import { Route, Routes } from 'react-router-dom';
import checkPermissions from '../HOCs/CheckPermissions';
import ChangeOrders from '../pages/Accounting/ACForms/ChangeOrders';
import EstimatedCost from '../pages/Accounting/ACForms/EstimatedCost';
import JobPhases from '../pages/Accounting/ACForms/JobPhases';
import NewContract from '../pages/Accounting/ACForms/NewContract';
import PayrollTimesheet from '../pages/Accounting/ACForms/PayrollTimesheet';
import PettyCashReimburusement from '../pages/Accounting/ACForms/PettyCashReimburusement';
import PoEntry from '../pages/Accounting/ACForms/PoEntry';
import ScheduleOfValues from '../pages/Accounting/ACForms/ScheduleOfValues';
import SubcontractEntry from '../pages/Accounting/ACForms/SubcontractEntry';

const FormRoutes = () => {
  const NewContractProtected = checkPermissions({
    sectionsToAccess: ['new_contract_setup'],
    Children: NewContract,
    blockAdminType: true,
  });

  const ScheduleOfValuesProtected = checkPermissions({
    sectionsToAccess: ['schedule_of_values'],
    Children: ScheduleOfValues,
    blockAdminType: true,
  });

  const EstimatedCostProtected = checkPermissions({
    sectionsToAccess: ['estimated_cost'],
    Children: EstimatedCost,
    blockAdminType: true,
  });

  const POEntryProtected = checkPermissions({
    sectionsToAccess: ['po_entry'],
    Children: PoEntry,
    blockAdminType: true,
  });

  const ChangeOrdersProtected = checkPermissions({
    sectionsToAccess: ['change_orders'],
    Children: ChangeOrders,
    blockAdminType: true,
  });

  const JobPhasesProtected = checkPermissions({
    sectionsToAccess: ['job_phase_codes'],
    Children: JobPhases,
    blockAdminType: true,
  });

  const SubEntryProtected = checkPermissions({
    sectionsToAccess: ['new_subcontract_setup'],
    Children: SubcontractEntry,
    blockAdminType: true,
  });

  const PRTimesheetProtected = checkPermissions({
    sectionsToAccess: ['new_pr_timesheet'],
    Children: PayrollTimesheet,
    blockAdminType: true,
  });

  const PettyCashReimburusementProtected = checkPermissions({
    sectionsToAccess: ['petty_cash_reimbursement'],
    Children: PettyCashReimburusement,
    blockAdminType: true,
  });

  return (
    <Routes>
      <Route path="/new_contract_setup" element={<NewContractProtected />} />
      <Route
        path="/schedule_of_values"
        element={<ScheduleOfValuesProtected />}
      />
      <Route path="/estimated_cost" element={<EstimatedCostProtected />} />
      <Route path="/job_phases" element={<JobPhasesProtected />} />
      <Route path="/po_entry" element={<POEntryProtected />} />
      <Route path="/change_orders" element={<ChangeOrdersProtected />} />
      <Route path="/subcontract_setup" element={<SubEntryProtected />} />
      <Route path="/payroll_timesheet" element={<PRTimesheetProtected />} />
      <Route
        path="/petty_cash_reimbursement"
        element={<PettyCashReimburusementProtected />}
      />
    </Routes>
  );
};

export default FormRoutes;
