import { useQuery } from '@tanstack/react-query';
import { ROLE_TYPE_OPTIONS } from '../../../utils/rolesConst';
import { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import DropdownStyled from '../../components/inputs/StyledInputs/DropdownStyled';
import FormControlledInput from '../../components/inputs/FormInputs/FormControlledInput';
import MultipleSelectStyled from '../../components/inputs/StyledInputs/MultipleSelectStyled';
import FormErrorMessage from '../../components/messages/FormErrorMessage';
import {
  fetchUserCompanies,
  fetchViewPointJobsSecurityGroups,
  fetchViewPointUsers,
} from '../../services/UsersService';
import { getRoles, getSystemSections } from '../../services/RolesService';
import { capitalizeString, getCompanyLabel } from '../../../utils/formatUtils';
import {
  Access,
  AccessForm,
  RoleSummary,
} from '../../Interfaces/Role.interfaces';
import { RadioButton } from 'primereact/radiobutton';
import FormControlledDropdown from '../../components/inputs/FormInputs/FormControlledDropdown';
import { Company, VPUser } from '../../Interfaces/User.interfaces';
import ModulesAccordion from '../roles/ModulesAccordion';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../components/messages/ErrorMessage';

export type RegisterFormFields = {
  firstname: string;
  lastname: string;
  email: string;
  userType: string;
  company: string[];
  roles: string[];
  isAdmin?: string;
  vpUser?: string;
  access: AccessForm[];
  secGroups?: number[];
};

type RegisterForm = {
  emailDisabled?: boolean;
  defaultRolesAccess?: Access[];
  defaultSet?: () => void;
};

const RegisterForm = ({
  emailDisabled = false,
  defaultRolesAccess,
  defaultSet,
}: RegisterForm) => {
  const {
    control,
    formState: { errors },
    unregister,
    register,
    setValue,
  } = useFormContext();
  const userType = useWatch({ name: 'userType' });
  const isAdmin = useWatch({ name: 'isAdmin' });
  const vpUser = useWatch({ name: 'vpUser' });
  const secGroups = useWatch({ name: 'secGroups' });

  const getCompany = useQuery({
    queryKey: ['getUserCompanies'],
    queryFn: () => fetchUserCompanies(),
    refetchOnWindowFocus: false,
  });

  const getRolesList = useQuery({
    queryKey: ['getUserRoles', userType],
    queryFn: ({ signal }) => getRoles({ roleType: userType }, signal),
    refetchOnWindowFocus: false,
    enabled: !!userType,
  });

  const getVPUsers = useQuery({
    queryKey: ['getVPUsers'],
    queryFn: () => fetchViewPointUsers(),
    refetchOnWindowFocus: false,
  });

  const getVPJobsSecGroups = useQuery({
    queryKey: ['getVPJobsSecGroups'],
    queryFn: () => fetchViewPointJobsSecurityGroups(),
    refetchOnWindowFocus: false,
  });

  const getSections = useQuery({
    queryKey: ['getSystemSections'],
    queryFn: ({ signal }) => {
      return getSystemSections(signal);
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    unregister('company', {
      keepValue: true,
      keepDefaultValue: true,
    });

    if (userType === 'internal') {
      register('company', {
        required: false,
      });
      register('isAdmin');
    } else {
      register('company', {
        required: {
          value: true,
          message: 'Company is a required field',
        },
      });
      unregister('isAdmin');
    }
  }, [userType, unregister, register]);

  useEffect(() => {
    unregister('roles', {
      keepValue: true,
      keepDefaultValue: true,
    });
    if (isAdmin === 'yes') {
      register('roles', {
        required: false,
      });
    } else {
      register('roles', {
        required: {
          value: true,
          message: 'User Roles is a required field',
        },
      });
    }
  }, [isAdmin, register, unregister]);

  if (getSections.isFetching) {
    return (
      <div className="flex mx-auto">
        <ProgressSpinner />
      </div>
    );
  }

  if (getSections.isError) {
    return (
      <div className="pt-8">
        <ErrorMessage
          content={'Failed to obtain sections! Please try again later.'}
        />
      </div>
    );
  }

  return (
    <div className="grid mx-3 justify-content-center">
      <div className="col-12 sm:col-6">
        <label htmlFor="firstname" className="text-standard block mb-2">
          First name
        </label>
        <FormControlledInput
          defaultValue=""
          formID="firstname"
          rules={{
            required: {
              value: true,
              message: 'First name is a required field',
            },
          }}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="lastname" className="text-standard block mb-2">
          Last name
        </label>
        <FormControlledInput
          defaultValue=""
          formID="lastname"
          rules={{
            required: {
              value: true,
              message: 'Last name is a required field',
            },
          }}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="email" className="text-standard block mb-2">
          Email
        </label>
        <FormControlledInput
          defaultValue=""
          formID="email"
          disabled={emailDisabled}
          rules={{
            required: {
              value: true,
              message: 'Email is a required field',
            },
          }}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="userType" className="text-standard block mb-2">
          User type
        </label>
        <Controller
          defaultValue=""
          control={control}
          name="userType"
          rules={{
            required: {
              value: true,
              message: 'User type is a required field',
            },
          }}
          render={({ field: { ref, onChange, ...otherFields } }) => (
            <DropdownStyled
              id="userType"
              options={ROLE_TYPE_OPTIONS}
              labelField="label"
              valueField="value"
              placeholder={'Select user type'}
              clearable={false}
              inputRef={ref}
              error={!!errors['userType']}
              onChange={(e) => {
                onChange(e);
                setValue('roles', null);
              }}
              {...otherFields}
            />
          )}
        />
        {errors['userType'] && (
          <FormErrorMessage
            errorMessage={errors['userType'].message as string}
          />
        )}
      </div>
      <div className="col-12 sm:col-6 sm:mr-auto">
        <label htmlFor="roles" className="text-standard block mb-2">
          User Roles
        </label>
        <Controller
          defaultValue=""
          control={control}
          name="roles"
          render={({ field: { ref, value, ...otherFields } }) => (
            <MultipleSelectStyled
              id="roles"
              isLoading={getRolesList.isLoading}
              isDisabled={getRolesList.isError || !userType}
              options={getRolesList.data?.roles?.map((role: RoleSummary) => {
                return {
                  ...role,
                  label: capitalizeString(role.name.replace(/_/g, ' ')),
                };
              })}
              labelField="label"
              valueField="id"
              placeholder={
                getRolesList.isError ? 'Failed to load roles!' : 'Select Roles'
              }
              clearable={false}
              inputRef={ref}
              error={!!errors['roles']}
              value={
                getRolesList.isLoading || getRolesList.isError ? null : value
              }
              {...otherFields}
            />
          )}
        />
        {errors['roles'] && (
          <FormErrorMessage errorMessage={errors['roles'].message as string} />
        )}
      </div>
      {userType === 'internal' && (
        <div className="col-12 sm:col-6">
          <label htmlFor="roles" className="text-standard block mb-2">
            Admin
          </label>
          <Controller
            defaultValue={'no'}
            control={control}
            name="isAdmin"
            rules={{
              required: {
                value: true,
                message: 'Admin is a required field',
              },
            }}
            render={({ field }) => (
              <div className="flex align-items-center justify-content-around text-standard h-3rem">
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="yes"
                    {...field}
                    value="yes"
                    checked={field.value === 'yes'}
                  />
                  <label htmlFor="yes" className="ml-1 mr-3">
                    Yes
                  </label>
                </div>
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="no"
                    {...field}
                    inputRef={field.ref}
                    value="no"
                    checked={field.value === 'no'}
                  />
                  <label htmlFor="no" className="ml-1 mr-3">
                    No
                  </label>
                </div>
              </div>
            )}
          />
          {errors['isAdmin'] && (
            <FormErrorMessage
              errorMessage={errors['isAdmin'].message as string}
            />
          )}
        </div>
      )}
      <div className="col-12">
        <label className="text-standard block mb-2">User Permissions</label>
        <div className="max-h-14rem overflow-y-scroll scrollbarStyled p-2 ">
          <ModulesAccordion
            sections={getSections.data}
            defaultAccess={defaultRolesAccess}
            defaultSet={defaultSet}
          />
        </div>
      </div>
      <div className="col-12">
        <label htmlFor="company" className="text-standard block mb-2">
          Company
        </label>
        <Controller
          defaultValue=""
          control={control}
          name="company"
          render={({ field: { ref, ...otherFields } }) => (
            <MultipleSelectStyled
              id="company"
              isLoading={getCompany.isLoading}
              isDisabled={getCompany.isError}
              options={getCompany.data?.map((company: Company) => ({
                ...company,
                label: getCompanyLabel(company),
              }))}
              labelField="label"
              valueField="id"
              placeholder={
                getCompany.isError
                  ? 'Failed to load companies!'
                  : 'Select company'
              }
              clearable={false}
              inputRef={ref}
              error={!!errors['company']}
              filter={true}
              resetFilterOnHide={true}
              {...otherFields}
            />
          )}
        />
        {errors['company'] && (
          <FormErrorMessage
            errorMessage={errors['company'].message as string}
          />
        )}
      </div>
      <div className="col-12">
        <label htmlFor="vpUser" className="text-standard block mb-2">
          VP User
        </label>
        <FormControlledDropdown
          formID="vpUser"
          options={getVPUsers.data?.map((vpUser: VPUser) => {
            const label = `${vpUser.FullName} - ${vpUser.VPUserName}`;
            return { ...vpUser, Label: label };
          })}
          labelField="Label"
          valueField="VPUserName"
          placeholder={
            getVPUsers.isError
              ? 'Failed to load vp users!'
              : 'Select ViewPoint User'
          }
          clearable={true}
          isDisabled={getVPUsers.isError || !!secGroups?.length}
          isLoading={getVPUsers.isFetching}
          filter={true}
          resetFilterOnHide={true}
        />
      </div>
      <div className="col-12">
        <label htmlFor="secGroups" className="text-standard block mb-2">
          VP Security Groups
        </label>
        <Controller
          defaultValue=""
          control={control}
          name="secGroups"
          render={({ field: { ref, ...otherFields } }) => (
            <MultipleSelectStyled
              id="secGroups"
              isLoading={getVPJobsSecGroups.isLoading}
              isDisabled={getVPJobsSecGroups.isError || !!vpUser}
              options={getVPJobsSecGroups.data}
              labelField="Description"
              valueField="SecurityGroup"
              placeholder={
                getVPJobsSecGroups.isError
                  ? 'Failed to load security groups!'
                  : 'Select security group'
              }
              clearable={true}
              inputRef={ref}
              filter={true}
              resetFilterOnHide={true}
              {...otherFields}
            />
          )}
        />
      </div>
    </div>
  );
};

export default RegisterForm;
