import {
  FileUpload,
  FileUploadHeaderTemplateOptions,
  FileUploadProps,
  FileUploadSelectParams,
  ItemTemplateOptions,
} from 'primereact/fileupload';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ErrorToast, { showToast } from '../../messages/ErrorAlert';
import { Toast } from 'primereact/toast';
import FileInputHeader from './../FileComponents/FileInputHeader';
import FileInputItem from './../FileComponents/FileInputItem';

export type SingleFileInputProps = FileUploadProps & {
  acceptedFormats: string[];
  contentClassName?: string;
  value?: File;
  onChange: (files: File | null) => void;
  totalLimit: number;
  totalLimitLabel: string;
  emptyMessage?: string;
};

const SingleFileInput = ({
  acceptedFormats,
  contentClassName,
  value,
  onChange,
  totalLimit,
  totalLimitLabel,
  emptyMessage,
  multiple = true,
  ...otherProps
}: SingleFileInputProps) => {
  const fileUploadRef = useRef<FileUpload>(null);
  const [sizeTotal, setSizeTotal] = useState(value?.size ?? 0);
  const toast = useRef<Toast>(null);

  const onSelect = (data: FileUploadSelectParams) => {
    const selFile = data.files[0];

    if (selFile.size <= totalLimit && selFile.name !== value?.name) {
      setSizeTotal(selFile.size);
      onChange(selFile);
    } else if (selFile.name === value?.name) {
      setSizeTotal(0);
      onChange(null);
    }

    if (selFile.size > totalLimit) {
      showToast(
        'error',
        toast,
        'Upload File Error',
        `The file ${selFile.name} is over the size limit.`,
        5000
      );
      fileUploadRef.current?.clear();
      setSizeTotal(0);
      onChange(null);
    }
  };

  const onRemove = () => {
    onChange(null);
    setSizeTotal(0);
    fileUploadRef.current?.clear();
  };

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    return (
      <FileInputHeader
        headerOptions={options}
        currentSize={sizeTotal}
        sizeLimitLabel={totalLimitLabel}
        sizelimit={totalLimit}
      />
    );
  };

  const itemTemplate = (file: File, props: ItemTemplateOptions) => {
    return <FileInputItem file={file} onRemove={props.onRemove} />;
  };

  useEffect(() => {
    if (!value) {
      fileUploadRef.current?.clear();
      setSizeTotal(0);
    }
  }, [value]);

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <FileUpload
        ref={fileUploadRef}
        accept={acceptedFormats.join(',')}
        contentClassName={contentClassName}
        multiple={false}
        headerTemplate={useCallback(headerTemplate, [
          sizeTotal,
          totalLimit,
          totalLimitLabel,
        ])}
        itemTemplate={(file, props) => itemTemplate(file as File, props)}
        emptyTemplate={
          value ? (
            <React.Fragment>
              <div
                role="progressbar"
                className="p-progressbar p-component p-progressbar-determinate"
                aria-valuemin={0}
                aria-valuenow={0}
                aria-valuemax={100}
              >
                <div
                  className="p-progressbar-value p-progressbar-value-animate"
                  style={{ width: '0%', display: 'flex' }}
                />
              </div>
              <div className="p-fileupload-files">
                <div className="p-fileupload-row">
                  <FileInputItem
                    file={value}
                    onRemove={() => {
                      onRemove();
                    }}
                  />
                </div>
              </div>
            </React.Fragment>
          ) : (
            <p className="m-0">
              {emptyMessage ?? (
                <React.Fragment>
                  <span className="mr-1">
                    Drag and drop files to upload. Allowed extensions:
                  </span>
                  {acceptedFormats.join(', ')}
                </React.Fragment>
              )}
            </p>
          )
        }
        cancelOptions={{ className: 'hidden' }}
        uploadOptions={{ className: 'hidden' }}
        onSelect={useCallback(onSelect, [onChange, totalLimit, value])}
        onRemove={useCallback(onRemove, [onChange])}
        {...otherProps}
      />
    </React.Fragment>
  );
};

export default SingleFileInput;
