import { formatUTCDate } from '../../../utils/formatUtils';
import { Link } from 'react-router-dom';
import ResumeCard from '../../components/cards/ResumeCard';
import ErrorMessage from '../../components/messages/ErrorMessage';
import { isTodayOrEarlier } from '../../../utils/dateUtils';
import { useQuery } from '@tanstack/react-query';
import { fetchPRPeriodDates } from '../../services/PayrollService';
import { useCompanyContext } from '../../context/CompanyContext';
import { ApiError } from '../../Interfaces/Generic.interfaces';

const DashboardPayroll = () => {
  const { selectedCompany } = useCompanyContext();

  const { data, isError, error } = useQuery({
    queryKey: ['getPayrollPeriodDates', selectedCompany],
    queryFn: ({ signal }) => fetchPRPeriodDates(selectedCompany!.id, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  if (isError) {
    return (
      <ResumeCard>
        <div className="relative pt-4 px-4 flex flex-column w-full">
          <Link to={`/accounting-dashboard/payroll`} className="w-fit">
            <div className="text-24px font-bold cursor-pointer totalColor hover:underline w-fit">
              Payroll
            </div>
          </Link>
          <div className="my-5 py-3">
            <ErrorMessage
              content={
                (error as ApiError)?.response?.data?.message ??
                'Failed to obtain data! Please try again later.'
              }
            />
          </div>
        </div>
      </ResumeCard>
    );
  }

  const { nextCheck, periodEnd, nextTimesheet } = data;

  return (
    <ResumeCard>
      <div className="p-3 px-4 flex flex-column">
        <Link to={`/accounting-dashboard/payroll`} className="w-fit">
          <div className="text-24px font-bold cursor-pointer totalColor hover:underline">
            Payroll
          </div>
        </Link>
        <div className="flex flex-wrap gap-4 mt-3 pl-2 pb-3">
          <div>
            <div className="text-15px pl-1">Next Check Date</div>
            <div className="text-25px dateText mt-1">
              {formatUTCDate(nextCheck)}
            </div>
          </div>
          <div>
            <div className="text-15px pl-1">Time Sheet Due</div>
            <div
              className={`text-25px dateText mt-1 ${
                isTodayOrEarlier(new Date(nextTimesheet)) && 'text-red-500'
              }`}
            >
              {formatUTCDate(nextTimesheet)}
            </div>
          </div>
          <div>
            <div className="text-15px pl-1">Period End Date</div>
            <div className="text-25px dateText mt-1">
              {formatUTCDate(periodEnd)}
            </div>
          </div>
        </div>
      </div>
    </ResumeCard>
  );
};

export default DashboardPayroll;
