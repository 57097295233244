import { useQuery } from '@tanstack/react-query';
import { formatUTCDate } from 'apps/tmr-frontend/src/utils/formatUtils';
import FormControlledDropdown from '../../../components/inputs/FormInputs/FormControlledDropdown';
import { useCompanyContext } from '../../../context/CompanyContext';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';
import { PRTimesheetSummary } from '../../../Interfaces/Accounting/ACForms.interfaces';
import { fetchPRTimesheets } from '../../../services/PayrollService';

export type PRTimesheetFilters = {
  submittedID?: string;
};

const TimesheetFilters = () => {
  const { selectedCompany } = useCompanyContext();
  const { rolesAcess } = useRolesAccessContext();
  const canApprove = rolesAcess?.find(
    (access) => access.report === 'new_pr_timesheet' && access.shouldApprove
  );

  const submittedTimesheets = useQuery({
    queryKey: ['getSubmittedPRTimesheets', selectedCompany],
    queryFn: ({ signal }) =>
      fetchPRTimesheets(
        {
          companyID: selectedCompany!.id,
          isSubmitted: true,
          isApproved: false,
        },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && canApprove),
    staleTime: 1000 * 60 * 10,
    cacheTime: 0,
  });

  return (
    <>
      {canApprove && (
        <div className="col">
          <FormControlledDropdown
            formID="submittedID"
            options={
              submittedTimesheets.data?.map((draft: PRTimesheetSummary) => {
                return {
                  ...draft,
                  label: `${formatUTCDate(draft.updatedAt || '')} - ${
                    draft.userName
                  }`,
                };
              }) || []
            }
            labelField="label"
            valueField="id"
            placeholder={
              submittedTimesheets.isError
                ? 'Failed to load submitted drafts!'
                : 'Approve Submitted Drafts'
            }
            isDisabled={
              submittedTimesheets.isError || !submittedTimesheets.data?.length
            }
            isLoading={submittedTimesheets.isFetching}
            clearable={true}
            filter={true}
            resetFilterOnHide={true}
          />
        </div>
      )}
    </>
  );
};

export default TimesheetFilters;
