import { formatBytesSize, transformBToMB } from '../../../../utils/fileUtil';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { FileUpload, ItemTemplateOptions } from 'primereact/fileupload';

export type FileInputItemProps = {
  file: File; 
  onRemove: (event: React.SyntheticEvent) => void;
};

const FileInputItem = ({ file, onRemove }: FileInputItemProps) => {
  let displayValue = formatBytesSize(file.size);
  if (file.size >= 1000000) {
    const sizeMB = transformBToMB(file.size) * 1000000;
    displayValue = formatBytesSize(sizeMB);
  }

  return (
    <div className="flex align-items-center flex-wrap">
      <div className="flex align-items-center" style={{ width: '40%' }}>
        <span className="flex flex-column text-left ml-3">{file.name}</span>
      </div>
      <Tag value={displayValue} severity="warning" className="px-3 py-2" />
      <Button
        type="button"
        icon="pi pi-times"
        className="p-button-outlined p-button-rounded p-button-danger ml-auto"
        onClick={onRemove}
      />
    </div>
  );
};

export default FileInputItem;
