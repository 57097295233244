import { Button } from 'primereact/button';
import { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import EarningsTableContainer from '../../features/Payroll/Earnings/EarningsTableContainer';
import EmployeeTableContainer from '../../features/Payroll/EmployeeList/EmployeeTableContainer';
import PRFilters, {
  PayrollFilters,
  REPORT_OPTIONS,
} from '../../features/Payroll/PRFilters';
import PRReport from '../../features/Payroll/PRReport/PRReport';
import PRReportHeader from '../../features/Payroll/PRReport/PRReportHeader';
import PRPeriodContainer from '../../features/Payroll/PRPeriod/PRPeriodContainer';
import checkPermissions from '../../HOCs/CheckPermissions';
import PRParams from '../../features/Payroll/PRParams';
import ReportSelector from '../../components/report-selector/ReportSelector';
import useProtectedReportOptions from '../../hooks/useProtectedReportOptions';
import FiltersMenu from '../../components/filters-menu/FiltersMenu';
import { reportsPerSection } from 'apps/tmr-frontend/src/utils/rolesConst';
import { useCompanyContext } from '../../context/CompanyContext';
import { Link } from 'react-router-dom';

const Payroll = () => {
  const { selectedCompany } = useCompanyContext();
  const prevSelectedCompanyRef = useRef<string | null>(null);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const methods = useForm<PayrollFilters>({
    defaultValues: {
      employeeStatus: 'active',
      period: 'pay',
    },
  });
  const { watch, resetField } = methods;
  const reportType = watch('reportType');

  const [protectedReports] = useProtectedReportOptions({
    reports: REPORT_OPTIONS,
  });

  const PayrollSummary = checkPermissions({
    sectionsToAccess: reportsPerSection['payroll'],
    Children: PRPeriodContainer,
  });

  const Employee = checkPermissions({
    sectionsToAccess: ['employee_list'],
    Children: EmployeeTableContainer,
  });
  const Earnings = checkPermissions({
    sectionsToAccess: ['employee_earnings'],
    Children: EarningsTableContainer,
    blockAdminType: 'internal',
  });
  const TimesheetButton = checkPermissions({
    sectionsToAccess: ['new_pr_timesheet'],
    Children: () => (
      <Link
        to={'/accounting-dashboard/new/payroll_timesheet'}
        className={`text-white h-fit`}
      >
        <Button className="h-fit buttonBackground border-0 text-xl w-fit py-3 px-3 radius-10px">
          New Timesheet
        </Button>
      </Link>
    ),
    blockAdminType: 'internal',
  });

  const handleReportChange = () => {
    resetField('employeeStatus');
    resetField('period');
  };

  useEffect(() => {
    if (prevSelectedCompanyRef.current === null) {
      prevSelectedCompanyRef.current = selectedCompany?.id || null;
    } else if (prevSelectedCompanyRef.current !== selectedCompany?.id) {
      methods.reset();
      prevSelectedCompanyRef.current = selectedCompany?.id || null;
    }
  }, [selectedCompany, methods]);

  return (
    <div className="h-full flex flex-column">
      <FormProvider {...methods}>
        <div className="mt-6 mx-3 flex gap-2 flex-wrap justify-content-center md:justify-content-start">
          <div className="flex flex-column gap-5  w-full sm:w-fit sm:flex-row sm:mr-auto">
            <a
              className="text-white h-fit"
              href={process.env['NX_PAYROLL_LINK']}
              target="_blank"
              rel="noreferrer"
            >
              <Button className="md:ml-5 h-fit buttonBackground border-0 text-xl w-fit py-3 px-3 radius-10px">
                {process.env['NX_PAYROLL_LINK_LABEL']}
              </Button>
            </a>
            <TimesheetButton />
          </div>
          <PayrollSummary />
        </div>
        <div className="flex flex-column  gap-2 mx-3  mt-3">
          <div className="text-standard mb-1">Select Report</div>
          <div className="flex flex-row gap-2 justify-content-between">
            <ReportSelector
              protectedReports={protectedReports}
              onChange={() => {
                handleReportChange();
              }}
            />
            <div className="flex w-full justify-content-between  gap-2">
              <FiltersMenu>
                <PRFilters />
              </FiltersMenu>
              <PRReport elementRef={tableRef} />
            </div>
          </div>
        </div>
        <div className="mb-3 printBackground pdfDivFitContent" ref={tableRef}>
          <PRReportHeader />
          {reportType === 'employee' && <Employee />}
          {reportType === 'earnings' && <Earnings />}
        </div>
        <PRParams />
      </FormProvider>
    </div>
  );
};

export default Payroll;
