import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useWatch } from 'react-hook-form';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { EmployeePCReimbursement } from '../../../Interfaces/Accounting/Payroll.interface';
import { fetchPCReimbursement } from '../../../services/PayrollService';
import { ReimbursementFilters } from './PCReimbursementFilters';
import PCReimbursementTable from './PCReimbursementTable';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';

const PCReimbursementTableContainer = () => {
  const { selectedCompany } = useCompanyContext();
  const { rolesAcess } = useRolesAccessContext();
  const access = rolesAcess?.find(
    (access) => access.report === 'petty_cash_reimbursement'
  );
  const submittedID = useWatch<ReimbursementFilters>({ name: 'submittedID' });

  const { data, isError, isFetching } = useQuery({
    queryKey: ['getPCReimbursement', selectedCompany, submittedID],
    queryFn: ({ signal }) => {
      return fetchPCReimbursement(
        selectedCompany!.id,
        access?.shouldApprove ? submittedID : undefined,
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  const formatData = (data: EmployeePCReimbursement[]) => {
    const newData = data.map((emp) => {
      const empCoy = { ...emp };

      if (empCoy.details) {
        empCoy.useDetails = true;
      } else {
        empCoy.details = [{}];
      }

      return empCoy;
    });

    newData.push({ empID: null, total: null, details: [{}] });
    return newData;
  };

  if (isFetching) {
    return (
      <div className="text-center mx-auto mt-3">
        <ProgressSpinner />
      </div>
    );
  }

  if (isError) {
    return (
      <ErrorMessage
        content={'Failed to obtain data! Please try again later.'}
      />
    );
  }

  return (
    <PCReimbursementTable
      employeesDefault={data.employees ? formatData(data.employees) : undefined}
      draftID={data?.id}
      draftIsSubmitted={data?.submitted}
      approvalMode={!!submittedID}
      access={access ?? { report: 'petty_cash_reimbursement' }}
    />
  );
};

export default PCReimbursementTableContainer;
