import React, { useCallback, useRef, useState } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import {
  formatCurrency,
  formatPercentage,
  formatUTCDate,
} from '../../../../utils/formatUtils';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import { ContractBill } from '../../../Interfaces/Accounting/ContractBillings.interface';
import { Checkbox } from 'primereact/checkbox';
import ConfirmationDialog from '../../../components/messages/ConfirmationDialog';
import LoadingButton from '../../../components/inputs/LoadingButton';
import { useMutation } from '@tanstack/react-query';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { Toast } from 'primereact/toast';
import { postCBNoBillingThisMonth } from '../../../services/ContractBillingsService';
import { useCompanyContext } from '../../../context/CompanyContext';

type ContractListTableProps = {
  contracts: ContractBill[];
};

const ContractListTable = React.forwardRef<
  HTMLDivElement,
  ContractListTableProps
>(({ contracts }, ref) => {
  const { selectedCompany } = useCompanyContext();
  const toast = useRef<Toast>(null);
  const defaultNoBilling = contracts.reduce((acc, contract) => {
    if (!contract.HasBillingThisPeriod) {
      acc.push(contract.Contract);
    }

    return acc;
  }, [] as string[]);
  const [baseNoBilling, setBaseNoBilling] = useState(new Set(defaultNoBilling));
  const [updateNoBillingContracts, setUpdateNoBillingContracts] = useState(
    new Set(defaultNoBilling)
  );
  const calcHeight = (rows: number) => {
    const headerHeight = 32;
    const footerHeight = 0;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const hasChange = (contractsSet: Set<string>, defaultSet: Set<string>) => {
    if (defaultSet.size !== contractsSet.size) {
      return true;
    }

    for (let item of defaultSet) {
      if (!contractsSet.has(item)) {
        return true;
      }
    }

    return false;
  };

  const submit = useMutation({
    mutationFn: () => {
      const month = new Date();
      month.setDate(1);
      month.setHours(0, 0, 0, 0);

      const removeContracts: string[] = [];
      for (let item of baseNoBilling) {
        if (!updateNoBillingContracts.has(item)) {
          removeContracts.push(item);
        }
      }

      const newContracts: string[] = [];
      for (let item of updateNoBillingContracts) {
        if (!baseNoBilling.has(item)) {
          newContracts.push(item);
        }
      }

      return postCBNoBillingThisMonth(
        selectedCompany!.id,
        month,
        newContracts,
        removeContracts
      );
    },
    onSuccess: () => {
      setBaseNoBilling(new Set(updateNoBillingContracts));

      showToast(
        'success',
        toast,
        'No Billing This Period',
        'The contracts were updated successfully!',
        3000
      );
    },
    onError: () => {
      showToast(
        'error',
        toast,
        'No Billing This Period',
        "The contracts couldn't be updated!",
        3000
      );
    },
  });

  return (
    <div className="pdfDivFitContent">
      <Table
        id="cb-contract-list"
        ref={ref}
        data={contracts}
        className={`mx-3 dashboardOptionShadow noFooter`}
        calcHeight={useCallback(calcHeight, [])}
        onRowMouseEnter={useCallback(onRowHover, [])}
        hideColumns={true}
      >
        <Column
          headerClassName={`tableHeader`}
          className="tableCell p-0 printHide"
          footerClassName="tableFooter "
        />
        <Column
          headerClassName={`tableHeader`}
          className="tableCell p-0 printHide"
          footerClassName="tableFooter "
        />
        <Column
          field="ProcessGroup"
          header="Bill Date"
          headerClassName={`tableHeader font-normal`}
          style={{ minWidth: '120px', maxWidth: '120px' }}
          className={`justify-content-center text-standard blackText tableCell`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="Contract"
          header="Contract #"
          style={{ minWidth: '140px', maxWidth: '140px' }}
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="ContractName"
          header="Contract Name"
          body={(cb) => {
            return <div className="scroll-text">{cb.ContractName}</div>;
          }}
          style={{ minWidth: '210px', maxWidth: '210px' }}
          headerClassName="tableHeader font-normal justify-content-center"
          className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="CurrContract"
          header="Contract Amount"
          body={(cb) => formatCurrency(cb.CurrContract)}
          style={{ minWidth: '180px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="PrevAmt"
          header="Total Billed"
          body={(cb) => (cb.PrevAmt ? formatCurrency(cb.PrevAmt) : '')}
          style={{ minWidth: '180px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="BilledRatio"
          header="Total Billed %"
          body={(cb) =>
            cb.BilledRatio ? formatPercentage(cb.BilledRatio * 100) : ''
          }
          style={{ minWidth: '180px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="InvTotal"
          header="Current Billing"
          body={(cb) => (cb.InvTotal ? formatCurrency(cb.InvTotal) : '')}
          style={{ minWidth: '180px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          key={`noBilling_${updateNoBillingContracts.size}`}
          field="HasBillingThisPeriod"
          header="No Billing this Period"
          body={(cb) => {
            return (
              <Checkbox
                className="printHide"
                checked={updateNoBillingContracts.has(cb.Contract)}
                onChange={(e) => {
                  if (e.checked) {
                    setUpdateNoBillingContracts((noBillings) => {
                      noBillings.add(cb.Contract);
                      return new Set(noBillings);
                    });
                  } else {
                    setUpdateNoBillingContracts((noBillings) => {
                      noBillings.delete(cb.Contract);

                      return new Set(noBillings);
                    });
                  }
                }}
              />
            );
          }}
          style={{ minWidth: '180px' }}
          headerClassName="tableHeader font-normal text-center"
          className="justify-content-center text-standard blackText tableCell printHide"
          footerClassName="tableFooter"
        />
        <Column
          field="LastBilled"
          header="Last Billed Date"
          body={(cb) => (cb.LastBilled ? formatUTCDate(cb.LastBilled) : null)}
          style={{ minWidth: '180px' }}
          headerClassName="tableHeader font-normal text-center"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          headerClassName={`tableHeader`}
          className="tableCell p-0 printHide"
          footerClassName="tableFooter "
        />
        <Column
          headerClassName={`tableHeader`}
          className="tableCell p-0 printHide"
          footerClassName="tableFooter "
        />
      </Table>
      <div className="w-fit ml-auto mr-3 mt-4 printHide">
        <ConfirmationDialog
          Button={
            <LoadingButton
              label="Update"
              fontSize="text-xl"
              isLoading={submit.isLoading}
              disabled={
                submit.isLoading ||
                !hasChange(updateNoBillingContracts, baseNoBilling)
              }
              type="button"
            />
          }
          onConfirm={() => submit.mutate()}
          message="Are you sure you want to update the contracts?"
        />
      </div>
      <ErrorToast toastRef={toast} />
    </div>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<ContractListTableProps>,
  nextTransactions: Readonly<ContractListTableProps>
) => {
  return prevTransactions.contracts === nextTransactions.contracts;
};

export default React.memo(ContractListTable, transactionsAreEqual);
