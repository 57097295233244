import React, { forwardRef } from 'react';
import {
  MultiSelect,
  MultiSelectItemTemplateType,
  MultiSelectProps,
} from 'primereact/multiselect';
import { Skeleton } from 'primereact/skeleton';

type MultipleSelectStyledProps = MultiSelectProps & {
  isDisabled?: boolean;
  options: unknown[];
  placeholder: string;
  isLoading?: boolean;
  labelField?: string;
  clearable?: boolean;
  valueField?: string;
  id?: string;
  inputRef?: React.Ref<HTMLSelectElement>;
  error?: boolean;
};

const MultipleSelectStyled = forwardRef<MultiSelect, MultipleSelectStyledProps>(
  (
    {
      id,
      isDisabled,
      labelField,
      valueField,
      isLoading,
      options,
      clearable,
      placeholder,
      error,
      inputRef,
      ...otherProps
    },
    ref
  ) => {
    const optionTemplate = (option: MultiSelectItemTemplateType) => {
      if (!option) {
        return null;
      }

      if (isLoading) {
        return (
          <div className="flex align-items-center p-2 disabled">
            <Skeleton height="1rem" />
          </div>
        );
      }

      return (
        <div className="blackText text-standard">
          {option[labelField as keyof MultiSelectItemTemplateType] ||
            option[valueField as keyof MultiSelectItemTemplateType] ||
            option}
        </div>
      );
    };

    return (
      <MultiSelect
        inputId={id}
        disabled={isDisabled}
        optionLabel={labelField}
        optionValue={valueField}
        options={
          isLoading
            ? [{ disabled: true }, { disabled: true }, { disabled: true }]
            : options
        }
        showClear={clearable}
        placeholder={placeholder}
        className={`fieldBorder text-standard w-full ${error && 'p-invalid'}`}
        itemTemplate={optionTemplate}
        inputRef={inputRef}
        ref={ref}
        display="comma"
        itemClassName="son-span-w-full"
        {...otherProps}
      />
    );
  }
);

export default MultipleSelectStyled;
