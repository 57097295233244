import { MODULES } from 'apps/tmr-frontend/src/utils/rolesConst';
import {
  APVendor,
  JobSummary,
} from '../../../Interfaces/Accounting/AccountsPayables.interfaces';

export const getPayableTypeName = (id: string) => {
  const payable = MODULES['accounts payables'].find(
    (payable) => payable.value === id
  );

  return payable?.label;
};

export const getVendorNames = (
  vendorsIDS: string[],
  vendorsList: APVendor[],
  vendorKey: keyof APVendor
) => {
  const filteredVendors = vendorsList?.filter((vendor: APVendor) =>
    vendorsIDS.includes(vendor[vendorKey] || '')
  );

  const vendorNames: string[] = filteredVendors?.map(
    (vendor: APVendor) => vendor.name
  );

  return vendorNames?.toString();
};

export const getJobNames = (selectedJobs: string[], jobList: JobSummary[]) => {
  const filteredJobs = jobList?.filter((job) => selectedJobs.includes(job.Job));

  const jobNames: string[] = filteredJobs?.map((job) => job.JobName);

  return jobNames?.toString();
};
