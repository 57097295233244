import { ColumnEditorOptions } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useRef, useState } from 'react';

type ColumnTextEditorProps = ColumnEditorOptions & {
  id: string;
  onFocus?: () => void;
  isPercentage?: boolean;
};

const ColumnTextEditor = ({
  id,
  editorCallback,
  value,
  onFocus,
  isPercentage,
}: ColumnTextEditorProps) => {
  const [prevValue, setPrevValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(value ?? '');
  const numberRegex = /^(-?(\d*(\.\d{0,2})?))$/;
  const numberPercentageRegex = /^(-?(\d+(\.\d{0,2})?|\.\d{0,2})?%?|-%|%)$/;

  const executeCallback = (text: string) => {
    try {
      if (editorCallback) {
        editorCallback(text);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 25);
  }, []);

  return (
    <React.Fragment>
      <InputText
        ref={inputRef}
        type="text"
        id={id}
        keyfilter={isPercentage ? numberRegex : numberPercentageRegex}
        validateOnly={true}
        onFocus={onFocus}
        onInput={(event, validatePattern) => {
          const target = event.currentTarget;
          const hasLength = target.value.length > 0;

          if (validatePattern && hasLength) {
            setPrevValue(target.value);
            return;
          }

          if (hasLength) {
            target.value = prevValue;
          }
        }}
        className="w-full"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          executeCallback(e.target.value);
        }}
        onBlur={(e) => {
          executeCallback(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            executeCallback(inputValue);
          }
        }}
      />
      {!isPercentage && (
        <Tooltip
          target={`#${id}`}
          content={`Enter amount or percentage by using '%' at the end`}
          position="top"
          event="focus"
        />
      )}
    </React.Fragment>
  );
};

export default ColumnTextEditor;
