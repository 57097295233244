import {
  DataTable,
  DataTableProps,
  DataTableRowClassNameOptions,
  DataTableSelectionChangeParams,
} from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelectChangeParams } from 'primereact/multiselect';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import MultipleSelectStyled from '../inputs/StyledInputs/MultipleSelectStyled';
import { ContextMenu } from 'primereact/contextmenu';
import { getNewHeaderGroup, getText } from '../../../utils/tableUtils';
import { compareReactElements } from 'apps/tmr-frontend/src/utils/utils';

type TableProps = DataTableProps & {
  children: React.ReactNode;
  data: unknown[];
  className?: string;
  calcHeight?: (rows: number) => number;
  rowClass?: (
    data: unknown,
    options?: DataTableRowClassNameOptions
  ) => object | string;
  onRowsAmountChange?: (rows: number) => void;
  defaultRowAmount?: number;
  fixedDefaultHeight?: number;
  selectionKey?: string;
  hideColumns?: boolean;
};

const rowsOptions = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '15', value: 15 },
  { label: '20', value: 20 },
  { label: '25', value: 25 },
  { label: '30', value: 30 },
];

const Table = React.forwardRef<HTMLDivElement, TableProps>(
  (
    {
      children,
      data,
      className,
      calcHeight,
      rowClass,
      onRowsAmountChange,
      defaultRowAmount,
      fixedDefaultHeight,
      selectionKey,
      hideColumns,
      headerColumnGroup,
      ...otherProps
    },
    ref
  ) => {
    const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>(
      {}
    );
    const [headerColumns, setHeaderColumns] = useState<
      React.ReactNode | undefined
    >(headerColumnGroup);
    const currentChildren = useRef<React.ReactNode>([]);
    const [columns, setColumns] = useState<React.ReactNode>([]);
    const [showColumns, setShowColumns] = useState<string[]>([]);
    const [columnOptions, setColumnOptions] = useState<string[]>([]);
    const dt = useRef<DataTable>(null);
    const cm = useRef<ContextMenu>(null);
    const items = [
      {
        label: 'Hide',
        icon: 'pi pi-eye-slash',
        command: (event: any) => {},
      },
    ];

    let isAvailableOption = false;
    rowsOptions.forEach((option) => {
      if (option.value === defaultRowAmount) {
        isAvailableOption = true;
      }
    });
    const [showRows, setShowRows] = useState(
      isAvailableOption && defaultRowAmount ? defaultRowAmount : 15
    );

    const onShowChange = (e: MultiSelectChangeParams) => {
      setShowColumns(e.value);
      if (Array.isArray(children)) {
        const columnsToShow = children.filter((child) => {
          return (
            !child.props?.header ||
            e.value.includes(getText(child.props?.header ?? ''))
          );
        });

        setColumns(columnsToShow);

        if (headerColumnGroup) {
          const newHeaderColumns = getNewHeaderGroup(
            headerColumnGroup as JSX.Element,
            columnOptions,
            (header: string) => e.value.includes(header)
          );

          setHeaderColumns(newHeaderColumns);
        }
      }
    };

    const selectRow = useCallback(
      (e: DataTableSelectionChangeParams) => {
        const key = selectionKey as string;
        const isSelected = selectedRows[e.value[key]];
        selectedRows[e.value[key]] = true;
        if (isSelected) {
          selectedRows[e.value[key]] = false;
        }
        setSelectedRows({ ...selectedRows });
      },
      [selectedRows, selectionKey]
    );

    const handleContextMenu = (event: MouseEvent, header: String) => {
      items[0].command = () => {
        const newShowCols = showColumns.filter((col) => col !== header);

        setShowColumns(newShowCols);

        if (Array.isArray(columns)) {
          const newCols = columns.filter(
            (col) => getText(col.props?.header ?? '') !== header
          );
          setColumns(newCols);
        }

        if (headerColumns) {
          const newHeaderColumns = getNewHeaderGroup(
            headerColumns as JSX.Element,
            columnOptions,
            (title: string) => header !== title
          );

          setHeaderColumns(newHeaderColumns);
        }
      };

      cm.current?.show(
        event as unknown as React.SyntheticEvent<Element, Event>
      );
    };

    useEffect(() => {
      if (onRowsAmountChange) {
        onRowsAmountChange(showRows);
      }
    }, [showRows, onRowsAmountChange]);

    useEffect(() => {
      if (
        Array.isArray(children) &&
        hideColumns &&
        currentChildren.current !== children
      ) {
        currentChildren.current = children;
        const hiddenCols = columnOptions.filter(
          (header) => !showColumns.includes(header)
        );

        const headers = children.reduce(
          (acc, child) => {
            const header = child.props?.header;
            const headerTitle = getText(header ?? '');

            if (headerTitle) {
              acc.columnOptions.push(headerTitle);

              if (!hiddenCols.includes(headerTitle)) {
                acc.columns.push(child);
                acc.showColumns.push(headerTitle);
              }
            } else {
              acc.columns.push(child);
            }

            return acc;
          },
          { columns: [], showColumns: [], columnOptions: [] }
        );

        setColumnOptions(headers.columnOptions);
        setShowColumns(headers.showColumns);
        setColumns(headers.columns);
      }
    }, [showColumns, columnOptions, children, hideColumns]);

    useEffect(() => {
      if (dt.current && cm.current && hideColumns) {
        const domEl = dt.current.getElement();

        const tableHead = domEl.querySelector('thead');
        const thElements = tableHead?.querySelectorAll('th') || [];

        const functionsJson: Record<string, (e: any) => void> = {};

        thElements.forEach((th) => {
          const headerText = th.querySelector('.p-column-title');
          const header = headerText?.textContent;

          if (header) {
            const wrappedHandleContextMenu = (e: MouseEvent) =>
              handleContextMenu(e, header);

            th.addEventListener('contextmenu', wrappedHandleContextMenu);
            functionsJson[header] = wrappedHandleContextMenu;
          }
        });

        return () => {
          thElements.forEach((th) => {
            const headerText = th.querySelector('.p-column-title');
            const header = headerText?.textContent;
            if (header) {
              const menuFunction = functionsJson[header];
              th.removeEventListener('contextmenu', menuFunction);
            }
          });
        };
      }

      return;
    }, [dt.current, hideColumns]);

    return (
      <React.Fragment>
        {(hideColumns || calcHeight) && (
          <div className="printHide flex flex-wrap  mx-3 mb-2 mt-3 gap-2">
            {hideColumns && (
              <div className="max-w-7 w-7">
                <MultipleSelectStyled
                  resetFilterOnHide={true}
                  showSelectAll={true}
                  filter={true}
                  options={columnOptions}
                  placeholder={'Select columns to show'}
                  value={showColumns}
                  onChange={onShowChange}
                  display="chip"
                />
              </div>
            )}
            {calcHeight && (
              <div className="ml-auto w-fit">
                <div className="mb-1 flex gap-2 align-items-center">
                  <span className="font-bold">Rows</span>
                  <Dropdown
                    className="w-fit"
                    value={showRows}
                    onChange={(e) => setShowRows(e.value)}
                    options={rowsOptions}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <ContextMenu model={items} ref={cm} />
        <DataTable
          ref={dt}
          rows={showRows}
          removableSort
          value={data}
          scrollable
          scrollHeight={`${
            calcHeight ? calcHeight(showRows) : fixedDefaultHeight ?? 400
          }px`}
          className={`tableStyled tablePrint ${className}`}
          stripedRows
          rowClassName={rowClass}
          onSelectionChange={selectionKey ? selectRow : undefined}
          selection={selectedRows}
          headerColumnGroup={headerColumns}
          {...otherProps}
        >
          {hideColumns ? columns : children}
        </DataTable>
      </React.Fragment>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<TableProps>,
  nextTransactions: Readonly<TableProps>
) => {
  let isEquals = true;
  Object.keys(prevTransactions).forEach(function (key) {
    if (
      prevTransactions[key as keyof TableProps] !==
        nextTransactions[key as keyof TableProps] &&
      key !== 'children'
    ) {
      isEquals = false;
    }
  });

  const prevChildren = prevTransactions.children;
  const nextChildren = nextTransactions.children;

  if (Array.isArray(prevChildren) && Array.isArray(nextChildren)) {
    if (prevChildren.length !== nextChildren.length) {
      isEquals = false;
    } else {
      for (let i = 0; i < prevChildren.length; i++) {
        const prevChild = prevChildren[i];
        const nextChild = nextChildren[i];

        if (
          React.isValidElement(prevChild) &&
          React.isValidElement(nextChild)
        ) {
          isEquals = isEquals && compareReactElements(prevChild, nextChild);
        } else {
          isEquals = isEquals && prevChild === nextChild;
        }
      }
    }
  } else {
    isEquals = isEquals && prevChildren === nextChildren;
  }

  return isEquals;
};

export default React.memo(Table, transactionsAreEqual);
