import axios from 'axios';
import { auth } from '../../firebase';
import { cleanJsonEmptyValues } from '../../utils/formatUtils';
import { Access } from '../Interfaces/Role.interfaces';

const apiUrl = process.env['NX_API_URL'];

export const fetchUserCompanies = async (companies?: number[]) => {
  const response = await axios.get(`${apiUrl}/api/company`, {
    params: { companies },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const data = response.data;
  return data;
};

export type getUsersArgs = {
  page: number;
  amountPerPage: number;
  userType?: string;
  isAdmin?: boolean;
  roles?: string[];
  info?: string;
};

export const getUsers = async (params: getUsersArgs, signal?: AbortSignal) => {
  const cleanedParams = cleanJsonEmptyValues(params);

  const response = await axios.get(`${apiUrl}/api/user`, {
    signal,
    params: cleanedParams,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const getUser = async (userID: string, signal?: AbortSignal) => {
  const response = await axios.get(`${apiUrl}/api/user/${userID}`, {
    signal,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const getUserAccess = async (signal?: AbortSignal) => {
  const response = await axios.get(`${apiUrl}/api/user/access`, {
    signal,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  return response.data;
};

export type usersArgs = {
  firstname: string;
  lastname: string;
  company?: string[];
  roles: string[];
  isAdmin: boolean;
  userType: string;
  access?: Access[];
};

export type postUsersArgs = usersArgs & {
  email: string;
};

export const postUsers = async (data: postUsersArgs) => {
  const cleanedData = cleanJsonEmptyValues(data);

  const response = await axios.post(`${apiUrl}/api/user`, cleanedData, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const putUsers = async (userID: string, data: usersArgs) => {
  const cleanedData = cleanJsonEmptyValues(data);

  const response = await axios.put(
    `${apiUrl}/api/user/${userID}`,
    cleanedData,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const postDeleteUsers = async (users: string[]) => {
  const response = await axios.post(
    `${apiUrl}/api/user/delete`,
    { users },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const deleteUser = async (userID: string) => {
  const response = await axios.delete(`${apiUrl}/api/user/${userID}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fetchViewPointUsers = async () => {
  const response = await axios.get(`${apiUrl}/api/user/vp`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const data = response.data;
  return data;
};

export const fetchViewPointJobsSecurityGroups = async () => {
  const response = await axios.get(`${apiUrl}/api/user/vp/jobsSecurityGroups`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const data = response.data;
  return data;
};
