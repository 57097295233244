import { useQuery } from '@tanstack/react-query';
import { FILE_SIZE_LIMIT } from 'apps/tmr-frontend/src/utils/fileConsts';
import {
  FILE_FORMATS,
  transformBToMB,
} from 'apps/tmr-frontend/src/utils/fileUtil';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FormControlledDropdown from '../../../components/inputs/FormInputs/FormControlledDropdown';
import FormControlledInput from '../../../components/inputs/FormInputs/FormControlledInput';
import SingleFileInput from '../../../components/inputs/StyledInputs/SingleFileInput';
import FormErrorMessage from '../../../components/messages/FormErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { APVendor } from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import { SLGeneralInfo } from '../../../Interfaces/Accounting/ACForms.interfaces';
import { fetchSLCheck } from '../../../services/ACFormsService';
import { fecthJobs } from '../../../services/JobsContractsService';
import { fetchVendors } from '../../../services/vendorsService';
import { getVendorKeyPerCompany } from '../../accountsPayables/apUtil';

export const UPLOAD_TYPES = [
  { label: 'File', value: 'file' },
  { label: 'Manual', value: 'manual' },
];

type GeneralInfoProps = {
  checkForm: (index: number) => void;
};

const GeneralInfo = ({ checkForm }: GeneralInfoProps) => {
  const { selectedCompany } = useCompanyContext();
  const vendorKey = getVendorKeyPerCompany(selectedCompany!.source);
  const navigate = useNavigate();
  const {
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    control,
  } = useFormContext<SLGeneralInfo>();
  const [availableSL, setAvailableSL] = useState<boolean | null>(null);
  const sl = useWatch({ name: 'sl' });
  const job = useWatch({ name: 'job' });
  const vendor = useWatch({ name: 'vendor' });
  const desc = useWatch({ name: 'description' });

  const jobs = useQuery({
    queryKey: ['getActiveJobs', selectedCompany],
    queryFn: ({ signal }) =>
      fecthJobs({ companyID: selectedCompany!.id, status: [1] }, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  const vendors = useQuery({
    queryKey: ['getPoVendors', selectedCompany],
    queryFn: ({ signal }) => {
      return fetchVendors(
        {
          company: selectedCompany!,
          active: true,
          types: ['Subcontractor'],
        },
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  const slCheck = useQuery({
    queryKey: ['getSLCheck', selectedCompany, sl],
    queryFn: ({ signal }) => {
      clearErrors(['sl']);
      setAvailableSL(null);
      return fetchSLCheck(selectedCompany!.id, sl, signal);
    },
    onSuccess: (data) => {
      setAvailableSL(data);
      if (!data) {
        setError('sl', { type: 'availability', message: 'DUPLICATE PO' });
      }
    },
    onError: () => {
      setAvailableSL(false);
      setError('sl', {
        type: 'availability',
        message: `COULDN'T CHECK AVAILABILITY`,
      });
    },
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && sl),
  });

  useEffect(() => {
    let newSL = '';
    const vendorData: APVendor = vendors.data?.find((vend: APVendor) => {
      return vend[vendorKey] === vendor;
    });

    if (job && vendorData && desc) {
      newSL = `${job.slice(-4)}-${vendorData.sortName}-${desc}`;
    }

    setValue('sl', newSL);
  }, [desc, job, vendors.data, vendor, setValue]);

  return (
    <div>
      <div className="grid mx-3 justify-content-center">
        <div className="col-12 sm:col-6">
          <label htmlFor="job" className="text-standard block mb-2">
            Job #
          </label>
          <FormControlledDropdown
            formID="job"
            options={jobs.data}
            labelField="Job"
            valueField="Job"
            placeholder={jobs.isError ? 'Failed to load jobs!' : 'Select Job'}
            isDisabled={jobs.isError}
            isLoading={jobs.isFetching}
            clearable={true}
            filter={true}
            resetFilterOnHide={true}
            rules={{
              required: {
                value: true,
                message: 'Job is a required field',
              },
            }}
          />
        </div>
        <div className="col-12 sm:col-6">
          <label htmlFor="job" className="text-standard block mb-2">
            Job Name
          </label>
          <FormControlledDropdown
            formID="job"
            options={jobs.data}
            labelField="Description"
            valueField="Job"
            placeholder={
              jobs.isError ? 'Failed to load job names!' : 'Select Job Name'
            }
            isDisabled={jobs.isError}
            isLoading={jobs.isFetching}
            clearable={true}
            filter={true}
            resetFilterOnHide={true}
            rules={{
              required: {
                value: true,
                message: 'Job Name is a required field',
              },
            }}
          />
        </div>
        <div className="col-12 sm:col-6">
          <label htmlFor="vendor" className="text-standard block mb-2">
            Vendor
          </label>
          <FormControlledDropdown
            formID="vendor"
            options={vendors.data}
            labelField="name"
            valueField={vendorKey}
            placeholder={
              vendors.isError ? 'Failed to load vendors!' : 'Select vendor'
            }
            isDisabled={vendors.isError}
            isLoading={vendors.isFetching}
            clearable={true}
            filter={true}
            resetFilterOnHide={true}
            rules={{
              required: {
                value: true,
                message: 'Vendor is a required field',
              },
            }}
          />
        </div>
        <div className="col-12 sm:col-6">
          <label htmlFor="vendor" className="text-standard block mb-2">
            Vendor Short Name
          </label>
          <FormControlledDropdown
            formID="vendor"
            options={vendors.data}
            placeholder={'Vendor short name'}
            labelField="sortName"
            valueField={vendorKey}
            clearable={false}
            disabled={true}
          />
        </div>
        <div className="col-12 sm:col-6">
          <label htmlFor="description" className="text-standard block mb-2">
            Subcontract Description
          </label>
          <FormControlledInput
            defaultValue=""
            formID="description"
            rules={{
              required: {
                value: true,
                message: 'Description is a required field',
              },
            }}
            autocomplete="off"
          />
        </div>
        <div className="col-12 sm:col-6">
          <label htmlFor="sl" className="text-standard block mb-2">
            Subcontract Name
          </label>
          <div className="p-inputgroup flex-1">
            <span className="p-inputgroup-addon">
              {slCheck.isFetching && (
                <ProgressSpinner className="w-1rem h-auto" />
              )}
              {availableSL && <i className="pi pi-check text-green-500" />}
              {!availableSL && availableSL !== null && (
                <i className="pi pi-times text-red-500" />
              )}
            </span>
            <FormControlledInput
              defaultValue=""
              formID="sl"
              rules={{
                required: {
                  value: true,
                  message: 'Name is a required field',
                },
                validate: () => {
                  return availableSL ?? 'DUPLICATE NAME';
                },
              }}
              autocomplete="off"
              disabled={true}
              hideErrors={true}
            />
          </div>
          {errors['sl'] && (
            <FormErrorMessage errorMessage={errors['sl'].message as string} />
          )}
        </div>
        <div className="col-12">
          <label htmlFor="attachment" className="text-standard block mb-2">
            Attachment
          </label>
          <Controller
            name="attachment"
            aria-label="file-input"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div>
                <SingleFileInput
                  value={value}
                  onChange={onChange}
                  acceptedFormats={FILE_FORMATS}
                  totalLimit={FILE_SIZE_LIMIT / 2}
                  totalLimitLabel={`${transformBToMB(FILE_SIZE_LIMIT / 2)} MB`}
                  emptyMessage={`Upload attachment file. Allowed extensions: ${FILE_FORMATS.join(
                    ', '
                  )}.`}
                  contentClassName={errors['attachment'] && 'border-red-500'}
                />
              </div>
            )}
          />
        </div>
        <div className="col-12 sm:col-6">
          <label htmlFor="upType" className="text-standard block mb-2">
            SOV Upload Type
          </label>
          <FormControlledDropdown
            formID="upType"
            labelField="label"
            valueField="value"
            options={UPLOAD_TYPES}
            defaultValue="file"
            placeholder={'Select type'}
            clearable={false}
          />
        </div>
        <div className="col-12 sm:col-6" />
      </div>
      <div className="flex flex-wrap flex-row-reverse gap-2 mb-3 mt-5">
        <Button
          className="w-15rem bluwaiBlue border-0 flex m-auto py-3"
          type="button"
          onClick={() => checkForm(0)}
          disabled={slCheck.isFetching}
        >
          <span className="mx-auto text-22px text-white">Next</span>
        </Button>
        <Button
          onClick={() => {
            navigate('/accounting-dashboard/new');
          }}
          className="w-15rem bluwaiRed border-0 flex m-auto py-3"
          type="button"
        >
          <span className="mx-auto text-22px text-white">Cancel</span>
        </Button>
      </div>
    </div>
  );
};

export default GeneralInfo;
