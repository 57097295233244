import React from 'react';
import ReportButton from '../../../components/report/ReportButton';
import * as XLSX from 'xlsx';
import { useCompanyContext } from '../../../context/CompanyContext';
import { useWatch } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import {
  BASE_PDF_STYLES,
  getContractName,
  getContractNames,
} from '../../../../utils/reportUtils';
import { fetchContractBillingFilters } from '../CBFilters';
import {
  fecthCBContracts,
  getSubmitedProgressBilling,
} from '../../../services/ContractBillingsService';
import {
  getBillItemStatusLabel,
  getCBReportTypeName,
  getSubmittedDraft,
} from './CBReportUtils';
import { excelCleanTable } from '../../../../utils/excelUtils';

const idPerCBReportType = {
  contractList: 'cb-contract-list',
  contractProgress: 'cb-contract-progress',
  monthlyBilling: 'cb-monthly-billing',
};

type CBReportProps = {
  elementRef: React.MutableRefObject<HTMLElement | null>;
};

const CBReport = ({ elementRef }: CBReportProps) => {
  const { selectedCompany } = useCompanyContext();
  const filters = useWatch<fetchContractBillingFilters>();
  const reportDate = new Date();

  const { data } = useQuery({
    queryKey: ['getCBActiveContracts', selectedCompany],
    queryFn: ({ signal }) =>
      fecthCBContracts({ companyID: selectedCompany!.id, status: 1 }, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const submittedPBs = useQuery({
    queryKey: [
      'getSubmittedProgressBillings',
      selectedCompany,
      filters.contract,
    ],
    queryFn: ({ signal }) =>
      getSubmitedProgressBilling(
        selectedCompany!.id,
        filters.contract ?? '',
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const monthlyContracts = useQuery({
    queryKey: ['getMonthlyContracts', selectedCompany],
    queryFn: ({ signal }) => {
      return fecthCBContracts(
        { companyID: selectedCompany!.id, department: 1 },
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const excelElementPreProcess = (element: HTMLElement) => {
    excelCleanTable(element);

    return element;
  };

  const contractProgressBeforeDownload = (
    sheet: XLSX.WorkSheet,
    element?: HTMLElement
  ) => {
    const cols = sheet['!cols'];
    if (cols) {
      cols[1] = { width: 12 };
    }

    const ref = sheet['!ref'];
    if (ref) {
      const boundary = ref.replace(/[A-Z]/g, '').split(':');
      const firstRow = 13;
      const lastRow = parseInt(boundary[1]);
      for (let i = firstRow; i <= lastRow; i++) {
        const cellConfig = sheet[`G${i}`];
        cellConfig.z = '0.00%';
      }

      const totalRetainage = element?.querySelector('#unreleasedRetainage');
      const grandTotal = element?.querySelector('#retainagePeriod');

      sheet[`F${lastRow + 2}`] = { t: 's', v: 'Unreleased Retainage:' };
      sheet[`G${lastRow + 2}`] = { t: 's', v: totalRetainage?.textContent };
      sheet[`H${lastRow + 2}`] = { t: 's', v: grandTotal?.textContent };

      sheet['!fullref'] = `A${1}:L${lastRow + 4}`;
      sheet['!ref'] = `A${1}:L${lastRow + 4}`;
    }
  };

  const excelBeforeDownload = (
    workBook: XLSX.WorkBook,
    element?: HTMLElement
  ) => {
    const sheet = workBook.Sheets['Sheet1'];
    const companyName = selectedCompany!.name;

    sheet['D2'] = { t: 's', v: companyName };
    sheet['D3'] = { t: 's', v: 'Contract Billings Report' };
    sheet['A4'] = { t: 's', v: 'Search criteria:' };
    sheet['B5'] = { t: 's', v: 'Report Type:' };
    sheet['C5'] = {
      t: 's',
      v: getCBReportTypeName(filters.reportType ?? ''),
    };

    let rowStart = 6;

    if (filters.contract) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Contract:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: filters.contract,
      };
      sheet[`B${rowStart + 1}`] = { t: 's', v: 'Contract Name:' };
      sheet[`C${rowStart + 1}`] = {
        t: 's',
        v: getContractName(filters.contract, data),
      };
      rowStart += 2;
    }

    if (filters.contracts) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Contracts:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: filters.contracts.join(', '),
      };
      sheet[`B${rowStart + 1}`] = { t: 's', v: 'Contract Names:' };
      sheet[`C${rowStart + 1}`] = {
        t: 's',
        v: getContractNames(filters.contracts, monthlyContracts.data),
      };
      rowStart += 2;
    }

    if (filters.itemsState && filters.reportType === 'contractProgress') {
      sheet[`B${rowStart}`] = { t: 's', v: 'Items State:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: getBillItemStatusLabel(filters.itemsState),
      };

      rowStart += 1;
    }

    if (filters.submittedID) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Submitted Draft:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: getSubmittedDraft(filters.submittedID, submittedPBs.data),
      };

      rowStart += 1;
    }

    if (filters.year && filters.reportType === 'monthlyBilling') {
      sheet[`B${rowStart}`] = { t: 's', v: 'Year:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: new Date(filters.year).getFullYear().toString(),
      };

      rowStart += 1;
    }

    if (filters.info && filters.reportType === 'contractProgress') {
      sheet[`B${rowStart}`] = { t: 's', v: 'Search term:' };
      sheet[`C${rowStart}`] = { t: 's', v: filters.info };
      rowStart += 1;
    }

    sheet[`A${rowStart}`] = { t: 's', v: 'Created at:' };
    sheet[`B${rowStart}`] = { t: 's', v: reportDate.toLocaleString() };

    const cols = sheet['!cols'];
    if (filters.reportType === 'contractProgress' && cols) {
      contractProgressBeforeDownload(sheet, element);
    }
  };

  return (
    <ReportButton
      elementToPrintRef={elementRef}
      fileName="CB_Report"
      pdfStyles={BASE_PDF_STYLES}
      excelPreprocess={excelElementPreProcess}
      excelBeforeDownload={excelBeforeDownload}
      tableOriginCell="A13"
      widthElementID={
        idPerCBReportType[filters.reportType as keyof typeof idPerCBReportType]
      }
    />
  );
};

export default CBReport;
