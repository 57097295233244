import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { Toast } from 'primereact/toast';
import RegisterForm, {
  RegisterFormFields,
} from '../../../features/user/RegisterForm';
import firebaseErrorMessages from '../../../components/errorMessages/firebase';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import RemoveUserButton from '../../../features/user/RemoveUserButton';
import SuccessMessage from '../../../features/user/SuccessMessage';
import {
  deleteUser,
  getUser,
  putUsers,
  usersArgs,
} from '../../../services/UsersService';
import { User } from '../../../Interfaces/User.interfaces';
import LoadingButton from '../../../components/inputs/LoadingButton';
import {
  processAccessForm,
  removeDuplicates,
} from 'apps/tmr-frontend/src/utils/roleUtils';
import { Access } from '../../../Interfaces/Role.interfaces';
import { getRolesInfo, postRoleArgs } from '../../../services/RolesService';

const UserUpdate = () => {
  const navigate = useNavigate();
  const { userID } = useParams();
  const toast = useRef<Toast>(null);
  const userDataSet = useRef(false);
  const [defaultAccess, setDefaultAccess] = useState<Access[]>([]);
  const [defaultSet, setDefaultSet] = useState(false);
  const formMethods = useForm<RegisterFormFields>();
  const { handleSubmit, setValue, watch } = formMethods;
  const [user, setUser] = useState<User>();
  const access = watch('access');
  const roles = watch('roles');

  const userResult = useQuery({
    queryKey: ['getUser', userID],
    queryFn: ({ signal }) => {
      return getUser(userID ?? '', signal);
    },
    onSuccess: (data) => {
      setUser(data);
      onGetUserSuccess(data);
    },
    refetchOnWindowFocus: false,
  });

  const getAccess = (rolesData?: postRoleArgs[]) => {
    if (!rolesData) {
      return [];
    }

    return removeDuplicates(
      rolesData.reduce((acc, role) => {
        acc = [...acc, ...role.access];

        return acc;
      }, [] as Access[])
    );
  };

  useQuery({
    queryKey: ['getRolesInfo', roles],
    queryFn: ({ signal }) => getRolesInfo(roles, signal),
    refetchOnWindowFocus: false,
    enabled: !!(roles && roles?.length),
    onSuccess: (data) => {
      setDefaultAccess(getAccess(data));
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as { code: string };
      showToast(
        'error',
        toast,
        'Role Access Error',
        firebaseErrorMessages[errorData?.code] ||
          `Couldn't find role access! please try again later`,
        3000
      );
    },
  });

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: (data: usersArgs) => {
      return putUsers(userID ?? '', data);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as { code: string };
      showToast(
        'error',
        toast,
        'Update User Error',
        firebaseErrorMessages[errorData?.code] ||
          'An error ocurred! please try again later',
        3000
      );
    },
  });

  const deleteUserResult = useMutation({
    mutationFn: () => {
      return deleteUser(userID ?? '');
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as { code: string };
      showToast(
        'error',
        toast,
        'Remove User Error',
        firebaseErrorMessages[errorData?.code] ||
          'An error ocurred! please try again later',
        3000
      );
    },
  });

  const onGetUserSuccess = (data: User) => {
    setValue('firstname', data.firstName);
    setValue('lastname', data.lastName);
    setValue('email', data.email);
    setValue('isAdmin', data.isAdmin ? 'yes' : 'no');
    setValue('company', data.companyID);
    setValue('userType', data.type);
    setValue('roles', data.roles?.map((role) => role.id) ?? []);
    setValue('vpUser', data.vpUser);
    setValue('secGroups', data.securityGroups);
  };

  const onSubmit = (data: RegisterFormFields) => {
    const { email, company, access, secGroups, ...otherData } = data;

    const sequenceReports = access.reduce((acc, item) => {
      if (Array.isArray(item.approvalOption)) {
        acc[item.report] = true;
      }

      return acc;
    }, {} as Record<string, boolean>);

    const list = processAccessForm(access);
    const removedRepeated = list.filter((acc) => {
      const def = defaultAccess.find((defAcc) => defAcc.report === acc.report);
      const isSequence = sequenceReports[acc.report];

      if (
        def &&
        acc.shouldNotify == def.shouldNotify &&
        acc.editable == def.editable &&
        (isSequence
          ? acc.approvalOrder == def.approvalOrder
          : acc.shouldApprove == def.shouldApprove)
      ) {
        return false;
      }

      return true;
    });

    const blocked = defaultAccess
      .filter(
        (defAccess) =>
          !list.find((element) => defAccess.report === element.report)
      )
      .map((acc) => {
        return { report: acc.report, blocked: true };
      });

    const payload = {
      ...otherData,
      isAdmin: otherData.isAdmin === 'yes',
      roles: otherData.roles || [],
      company: company?.length ? company : undefined,
      access: [...removedRepeated, ...blocked],
      securityGroups: secGroups?.length ? secGroups : undefined,
    };

    mutate(payload);
  };

  useEffect(() => {
    if (access && user?.access && defaultSet && !userDataSet.current) {
      access.forEach((acc, index) => {
        const report = acc.report;
        const newAccess = { ...acc };
        const match = user.access?.find(
          (element) =>
            element.report === report.replace(/ /g, '_').toLocaleLowerCase()
        );

        if (match) {
          const {
            editable,
            shouldApprove,
            shouldNotify,
            approvalOrder,
            blocked,
          } = match;

          if (editable != null) {
            newAccess.editable = editable || 'view-only';
          } else {
            delete newAccess.editable;
          }

          if (shouldNotify != null) {
            newAccess.shouldNotify = shouldNotify;
          } else {
            delete newAccess.shouldNotify;
          }

          if (shouldApprove != null) {
            if (Array.isArray(acc.approvalOption)) {
              newAccess.shouldApprove = approvalOrder;
            } else {
              newAccess.shouldApprove = shouldApprove;
            }
          } else {
            delete newAccess.shouldApprove;
          }

          if (blocked) {
            newAccess.selected = false;
            delete newAccess.editable;
            delete newAccess.shouldNotify;
            delete newAccess.shouldApprove;
          } else {
            newAccess.selected = true;
          }

          setValue(`access.${index}`, newAccess);
        }
      });
      userDataSet.current = true;
    }
  }, [access, user, defaultSet]);

  useEffect(() => {
    if (!roles || !roles?.length) {
      setDefaultAccess([]);
    }
  }, [roles]);

  if (userResult.isFetching) {
    return (
      <div className="flex mx-auto">
        <ProgressSpinner />
      </div>
    );
  }

  if (userResult.error) {
    const error = userResult.error as AxiosError;
    const errorData = error?.response?.data as { message: string };
    return (
      <div className="pt-8">
        <ErrorMessage
          content={
            errorData.message ||
            'Failed to obtain user data! Please try again later.'
          }
        />
      </div>
    );
  }

  return (
    <div className="flex flex-column justify-content-center gap-5 pt-8">
      <ErrorToast toastRef={toast} />
      <div className="text-35px font-bold text-center mb-5 relative">
        <div>Update User</div>
        {!isSuccess && !deleteUserResult.isSuccess && (
          <div className="flex justify-content-center lg:absolute top-0 right-0 mt-4 lg:mt-0 lg:mr-8">
            <RemoveUserButton
              onAccept={deleteUserResult.mutate}
              isLoading={deleteUserResult.isLoading}
            />
          </div>
        )}
      </div>
      {!isSuccess && !deleteUserResult.isSuccess && (
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="max-w-600px mx-auto flex flex-column gap-5"
          >
            <RegisterForm
              emailDisabled={true}
              defaultRolesAccess={defaultAccess}
              defaultSet={() => {
                setDefaultSet(true);
              }}
            />
            <div className="flex flex-wrap gap-2 mb-3">
              <Button
                onClick={() => {
                  navigate('/admin/users');
                }}
                className="w-15rem bluwaiRed border-0 flex m-auto py-3"
              >
                <span className="mx-auto text-22px text-white">Cancel</span>
              </Button>
              <LoadingButton
                isLoading={isLoading}
                label="Update"
                className="w-15rem bluwaiBlue border-0 flex m-auto py-3"
                fontSize="text-22px"
              />
            </div>
          </form>
        </FormProvider>
      )}
      {isSuccess && <SuccessMessage message="The user has been updated!" />}
      {deleteUserResult.isSuccess && (
        <SuccessMessage message="The user has been removed!" />
      )}
    </div>
  );
};

export default UserUpdate;
