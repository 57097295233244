import { FormProvider, useForm } from 'react-hook-form';
import FiltersMenu from '../../../components/filters-menu/FiltersMenu';
import PCReimbursementFilters, {
  ReimbursementFilters,
} from '../../../features/acForms/PettyCashReimbursement/PCReimbursementFilters';
import PCReimbursementParams from '../../../features/acForms/PettyCashReimbursement/PCReimbursementParams';
import PCReimbursementTableContainer from '../../../features/acForms/PettyCashReimbursement/PCReimbursementTableContainer';

const PettyCashReimburusement = () => {
  const methods = useForm<ReimbursementFilters>();

  return (
    <div className="flex flex-column justify-content-center gap-1 pt-4">
      <div className="text-35px font-bold text-center mb-2">
        Petty Cash Reimbursement
      </div>
      <FormProvider {...methods}>
        <div className="mt-5 px-3 flex w-full justify-content-between">
          <FiltersMenu>
            <PCReimbursementFilters />
          </FiltersMenu>
        </div>
        <div className="mt-1 printBackground pdfDivFitContent">
          <PCReimbursementTableContainer />
        </div>
        <PCReimbursementParams />
      </FormProvider>
    </div>
  );
};

export default PettyCashReimburusement;
