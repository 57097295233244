import { Column } from 'primereact/column';
import {
  DataTableExpandedRows,
  DataTableRowMouseEventParams,
} from 'primereact/datatable';
import React, { useCallback, useState } from 'react';
import Table from '../../../components/Table/Table';
import { animateCellText } from 'apps/tmr-frontend/src/utils/htmlUtils';
import { useQuery } from '@tanstack/react-query';
import { fetchPREmployeeList } from '../../../services/PayrollService';
import { useCompanyContext } from '../../../context/CompanyContext';
import DropdownStyled from '../../../components/inputs/StyledInputs/DropdownStyled';
import {
  fecthJobs,
  fetchJCJobPhases,
} from '../../../services/JobsContractsService';
import { InputNumber } from 'primereact/inputnumber';
import ExpandIcon from '../PayrollTimesheet/ExpandIcon';
import PCReimbursementTableDetails from './PCReimbursementTableDetails';
import { fecthGLAccounts } from '../../../services/GLAccountService';
import { JCJobSummary } from '../../../Interfaces/Accounting/JobsContracts.interface';
import {
  Employee,
  EmployeePCReimbursement,
  PCReimbursementDetails,
} from '../../../Interfaces/Accounting/Payroll.interface';
import { GLAccount } from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import PCReimbursementProcess from './PCReimbursementProcess';
import PCReimbursementFile from './PCReimbursementFile';
import { FILE_SIZE_LIMIT } from 'apps/tmr-frontend/src/utils/fileConsts';
import FormErrorMessage from '../../../components/messages/FormErrorMessage';
import { Access } from '../../../Interfaces/Role.interfaces';

type PCReimbursementTableProps = {
  employeesDefault?: EmployeePCReimbursement[];
  draftID?: string;
  draftIsSubmitted?: boolean;
  approvalMode?: boolean;
  access: Access;
};

const PCReimbursementTable = ({
  employeesDefault,
  draftID,
  draftIsSubmitted,
  approvalMode,
  access,
}: PCReimbursementTableProps) => {
  const { selectedCompany } = useCompanyContext();
  const [empData, setEmpData] = useState<EmployeePCReimbursement[]>(
    employeesDefault ?? [{ empID: null, total: null, details: [{}] }]
  );
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>({});
  const [fileLimit, setFileLimit] = useState<number>(FILE_SIZE_LIMIT);
  const [showErrors, setShowErrors] = useState(false);
  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const empRequest = useQuery({
    queryKey: ['getPRActiveEmployees', selectedCompany],
    queryFn: ({ signal }) => {
      return fetchPREmployeeList(
        { companyID: selectedCompany!.id, status: true },
        signal
      );
    },
    onSuccess: (data) => {
      const empJson = (data as Employee[]).reduce((acc, emp) => {
        acc[emp.EmpID] = { firstName: emp.FirstName, lastName: emp.LastName };
        return acc;
      }, {} as Record<number, { firstName: string; lastName: string }>);

      setEmpData((emps) => {
        const newEmps = emps.map((employee) => {
          const names = empJson[employee.empID ?? 0];

          if (names) {
            employee.firstName = names.firstName;
            employee.lastName = names.lastName;
          }

          return employee;
        });

        return newEmps;
      });
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });
  const empItems =
    empRequest.data?.map((emp: Employee) => {
      return { ...emp, label: `${emp.EmpID} ${emp.FirstName} ${emp.LastName}` };
    }) ?? [];

  const jobs = useQuery({
    queryKey: ['getPRJobs', selectedCompany],
    queryFn: ({ signal }) =>
      fecthJobs({ companyID: selectedCompany!.id, status: [1] }, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });
  const jobItems =
    jobs.data?.map((job: JCJobSummary) => {
      return { ...job, label: `${job.Job} ${job.Description}` };
    }) ?? [];

  const glAccounts = useQuery({
    queryKey: ['getPCRglAccounts', selectedCompany],
    queryFn: ({ signal }) =>
      fecthGLAccounts(selectedCompany!.id, [63, 64, 65, 66, 67, 68], signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });
  const glAccountsItems =
    glAccounts.data?.map((glAccount: GLAccount) => {
      return {
        ...glAccount,
        label: `${glAccount.GLAcct} ${glAccount.Description}`,
      };
    }) ?? [];

  const setEmpDetails = (emp: EmployeePCReimbursement) => {
    let hasDetails = false;
    emp.details?.forEach((detail) => {
      const keysLength = Object.keys(detail).length;

      hasDetails = hasDetails || !!keysLength;
    });

    emp.useDetails = hasDetails;
  };

  const updateDetails = (
    empID: number,
    detailIndex: number,
    detail: PCReimbursementDetails
  ) => {
    let empIndex = 0;
    const selectedEmp = empData.find((emp, index) => {
      if (emp.empID === empID) {
        empIndex = index;
        return true;
      }

      return false;
    });

    if (!selectedEmp) {
      return;
    }

    if (!selectedEmp.details) {
      return;
    }

    const empCopy = { ...selectedEmp };
    const { newDetails, selectedDetail } = empCopy.details!.reduce(
      (acc, prevDetail, index) => {
        if (index === detailIndex) {
          acc.selectedDetail = prevDetail;
          acc.newDetails.push(detail);
        } else {
          acc.newDetails.push(prevDetail);
        }

        return acc;
      },
      {
        newDetails: [] as PCReimbursementDetails[],
        selectedDetail: null as PCReimbursementDetails | null,
      }
    );

    if (!selectedDetail) {
      return;
    }

    empCopy.details = newDetails;

    const prevUseDetails = empCopy.useDetails;
    setEmpDetails(empCopy);
    if (!prevUseDetails && empCopy.useDetails) {
      empCopy.total = null;
      delete empCopy.amount;
      delete empCopy.job;
      delete empCopy.phaseCode;
      delete empCopy.overHead;
    }

    if (selectedDetail.amount !== detail.amount) {
      empCopy.total =
        (empCopy.total ?? 0) -
        (selectedDetail.amount ?? 0) +
        (detail.amount ?? 0);

      if (!empCopy.total) {
        empCopy.total = null;
      }
    }

    const empList = [...empData];
    empList[empIndex] = empCopy;
    setEmpData(empList);
  };

  const addNewDetail = (empID: number) => {
    let empIndex = 0;
    const selectedEmp = empData.find((emp, index) => {
      if (emp.empID === empID) {
        empIndex = index;
        return true;
      }

      return false;
    });

    if (!selectedEmp) {
      return;
    }

    const empCopy = { ...selectedEmp };
    empCopy.details?.push({});
    empCopy.details = [...(empCopy.details || [])];

    const empList = [...empData];
    empList[empIndex] = empCopy;
    setEmpData(empList);
  };

  const removeDetail = (empID: number, detailIndex: number) => {
    let empIndex = 0;
    const selectedEmp = empData.find((emp, index) => {
      if (emp.empID === empID) {
        empIndex = index;
        return true;
      }

      return false;
    });

    if (!selectedEmp) {
      return;
    }

    if (!selectedEmp.details) {
      return;
    }

    const empCopy = { ...selectedEmp };
    const { remainingDetails, removedDetail } = empCopy.details!.reduce(
      (acc, detail, index: number) => {
        if (detailIndex === index) {
          acc.removedDetail = detail;
        } else {
          acc.remainingDetails.push(detail);
        }

        return acc;
      },
      {
        remainingDetails: [] as PCReimbursementDetails[],
        removedDetail: null as PCReimbursementDetails | null,
      }
    );

    if (!removedDetail) {
      return;
    }

    empCopy.total = (empCopy.total ?? 0) - (removedDetail.amount ?? 0);

    if (!empCopy.total) {
      empCopy.total = null;
    }

    empCopy.details = remainingDetails;
    setEmpDetails(empCopy);
    const empList = [...empData];
    empList[empIndex] = empCopy;
    setEmpData(empList);
  };

  const rowExpansionTemplate = (data: EmployeePCReimbursement) => {
    return (
      <PCReimbursementTableDetails
        details={data.details ?? []}
        empID={data.empID!}
        addNewDetail={addNewDetail}
        removeDetail={removeDetail}
        updateDetails={updateDetails}
        showErrors={showErrors}
      />
    );
  };

  const updateAttachments = () => {
    setEmpData((data) => {
      data.forEach((emp) => {
        if (emp.newAttachment) {
          emp.attachment = emp.newAttachment;
          delete emp.newAttachment;
        }
      });

      return [...data];
    });

    setFileLimit(FILE_SIZE_LIMIT);
  };

  let total = 0;
  empData.forEach((data: EmployeePCReimbursement) => {
    total += data.total ?? 0;
  });

  return (
    <React.Fragment>
      <Table
        id="pr-petty-cash-reimbursement"
        data={empData}
        dataKey="empID"
        expandedRows={expandedRows}
        rowExpansionTemplate={rowExpansionTemplate}
        className={`mx-3 dashboardOptionShadow with-expanded noPadding noHover`}
        onRowMouseEnter={useCallback(onRowHover, [])}
        fixedDefaultHeight={790}
      >
        <Column
          field="empID"
          header="Emp ID"
          body={(row, options) => {
            return (
              <span className="w-full">
                <DropdownStyled
                  options={empItems}
                  labelField="label"
                  valueField="EmpID"
                  isLoading={empRequest.isLoading}
                  error={empRequest.isError}
                  className="h-full min-h-40px my-auto printHide"
                  filter={true}
                  value={row.empID}
                  onChange={(e) => {
                    setEmpData((data) => {
                      const rowCopy = { ...row };

                      if (!e.target.value) {
                        delete rowCopy.empID;
                        delete rowCopy.firstName;
                        delete rowCopy.lastName;
                      } else {
                        const selectedEmp = empRequest.data.find(
                          (emp: Employee) => emp.EmpID === e.target.value
                        );

                        rowCopy.empID = selectedEmp.EmpID;
                        rowCopy.firstName = selectedEmp.FirstName;
                        rowCopy.lastName = selectedEmp.LastName;
                      }

                      data[options.rowIndex] = rowCopy;

                      if (
                        e.target.value &&
                        options.rowIndex === data.length - 1
                      ) {
                        data.push({ empID: null, total: null, details: [{}] });
                      }

                      return [...data];
                    });
                  }}
                />
                <span className="printMedia printShow">{row.empID}</span>
              </span>
            );
          }}
          style={{ minWidth: '100px' }}
          headerClassName="tableHeader font-normal text-center"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="lastName"
          header="Last Name"
          body={(pr) => {
            return <div className="scroll-text">{pr.lastName}</div>;
          }}
          style={{ minWidth: '180px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className=" text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="firstName"
          header="First Name"
          body={(pr) => {
            return <div className="scroll-text">{pr.firstName}</div>;
          }}
          style={{ minWidth: '160px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
          footer="Total"
          sortable
        />
        <Column
          field="total"
          header="Total Amount"
          body={(record) => {
            if (record.total === undefined || record.total === null) {
              return;
            }

            return record.total;
          }}
          style={{ minWidth: '100px' }}
          headerClassName="tableHeader font-normal"
          className="text-standard blackText tableCell justify-content-center"
          footerClassName="tableFooter block text-center border-top-2 mt-4"
          footer={total}
          sortable
        />
        <Column
          field="amount"
          header="Amount"
          body={(row, options) => {
            if (expandedRows[row.empID]) {
              return;
            }

            if (row.useDetails) {
              return (
                <div className="mx-auto printHide">Expand for details</div>
              );
            }

            return (
              <span className="text-center w-full">
                <InputNumber
                  className="printHide"
                  inputClassName="w-full blackText text-standard text-center"
                  value={row.amount}
                  onBlur={(e) => {
                    setEmpData((data) => {
                      const rowCopy = { ...row };

                      if (e.target.value) {
                        const amount = parseFloat(
                          e.target.value?.replace(/,/g, '')
                        );
                        rowCopy.amount = amount;
                        rowCopy.total = amount;
                      } else {
                        delete rowCopy.amount;
                        delete rowCopy.total;
                      }

                      data[options.rowIndex] = rowCopy;

                      return [...data];
                    });
                  }}
                  disabled={row.useDetails}
                  maxFractionDigits={3}
                />
                <span className="printMedia printShow">{row.amount}</span>
              </span>
            );
          }}
          style={{ minWidth: '100px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell"
          footerClassName="tableFooter"
        />
        <Column
          field="job"
          header="Job Number"
          body={(row, options) => {
            if (row.useDetails) {
              return;
            }

            const selectedInfo = jobItems.find(
              (job: JCJobSummary) => job.Job === row.job
            );

            return (
              <div className="w-full">
                <span className="h-full">
                  <DropdownStyled
                    options={jobItems}
                    labelField="label"
                    valueField="Job"
                    disabled={!!row.overHead || row.useDetails}
                    isLoading={jobs.isLoading}
                    error={jobs.isError}
                    className={`h-full min-h-40px printHide ${
                      showErrors && !(row.job || row.overHead) && 'p-invalid'
                    }`}
                    filter={true}
                    value={row.job}
                    onChange={(e) => {
                      setEmpData((data) => {
                        const rowCopy = { ...row };
                        rowCopy.job = e.target.value;
                        delete rowCopy.phaseCode;
                        data[options.rowIndex] = rowCopy;

                        return [...data];
                      });
                    }}
                  />
                  <span className="printMedia printShow">
                    {selectedInfo?.label}
                  </span>
                </span>
                {showErrors && !(row.job || row.overHead) && (
                  <FormErrorMessage errorMessage="Job or OverHead is required" />
                )}
              </div>
            );
          }}
          style={{ minWidth: '200px', maxWidth: '200px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell"
          footerClassName="tableFooter"
        />
        <Column
          field="phaseCode"
          header="Phase Code"
          body={(row, options) => {
            const job = row.job;
            const phases = useQuery({
              queryKey: ['getJCJobPhases', selectedCompany, job],
              queryFn: ({ signal }) => {
                return fetchJCJobPhases(
                  {
                    companyID: selectedCompany!.id,
                    job,
                  },
                  signal
                );
              },
              refetchOnWindowFocus: false,
              enabled: !!(selectedCompany && job),
            });

            if (!job) {
              return;
            }

            return (
              <div className="w-full">
                <span className="h-full">
                  <DropdownStyled
                    options={phases.data}
                    labelField="Label"
                    valueField="Phase"
                    isLoading={phases.isLoading}
                    error={phases.isError}
                    className={`h-full min-h-40px  printHide ${
                      showErrors && row.job && !row.phaseCode && 'p-invalid'
                    }`}
                    filter={true}
                    value={row.phaseCode}
                    onChange={(e) => {
                      setEmpData((data) => {
                        const rowCopy = { ...row };
                        rowCopy.phaseCode = e.target.value;
                        data[options.rowIndex] = rowCopy;

                        return [...data];
                      });
                    }}
                  />
                  <span className="printMedia printShow">{row.phaseCode}</span>
                </span>
                {showErrors && row.job && !row.phaseCode && (
                  <FormErrorMessage errorMessage="PhaseCode is required" />
                )}
              </div>
            );
          }}
          style={{ minWidth: '200px', maxWidth: '200px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell"
          footerClassName="tableFooter"
        />
        <Column
          field="overHead"
          header="Overhead"
          body={(row, options) => {
            if (row.useDetails) {
              return;
            }

            return (
              <div className="w-full">
                <span className="h-full">
                  <DropdownStyled
                    options={glAccountsItems}
                    labelField="label"
                    valueField="GLAcct"
                    disabled={!!row.job || row.useDetails}
                    isLoading={glAccounts.isLoading}
                    error={glAccounts.isError}
                    className={`h-full min-h-40px  printHide ${
                      showErrors && !(row.job || row.overHead) && 'p-invalid'
                    }`}
                    filter={true}
                    value={row.overHead}
                    onChange={(e) => {
                      setEmpData((data) => {
                        const rowCopy = { ...row };
                        rowCopy.overHead = e.target.value;
                        data[options.rowIndex] = rowCopy;

                        return [...data];
                      });
                    }}
                  />
                  <span className="printMedia printShow">{row.overHead}</span>
                </span>
                {showErrors && !(row.job || row.overHead) && (
                  <FormErrorMessage errorMessage="Job or OverHead is required" />
                )}
              </div>
            );
          }}
          style={{ minWidth: '200px', maxWidth: '200px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell"
          footerClassName="tableFooter"
        />
        <Column
          body={(record, options) => {
            return (
              <PCReimbursementFile
                prevAttachment={record.attachment}
                attachment={record.newAttachment}
                totalLimit={fileLimit}
                draftID={draftID}
                empID={record.empID}
                onChange={(file) => {
                  setEmpData((data) => {
                    const rowCopy = { ...record };

                    if (!file) {
                      setFileLimit((amount) => {
                        return amount + (rowCopy.newAttachment?.size ?? 0);
                      });
                      delete rowCopy.newAttachment;
                    } else {
                      rowCopy.newAttachment = file;
                      setFileLimit((amount) => {
                        return amount - (file.size ?? 0);
                      });
                    }

                    data[options.rowIndex] = rowCopy;
                    return [...data];
                  });
                }}
              />
            );
          }}
          style={{ minWidth: '30px', maxWidth: '30px' }}
          headerClassName={`tableHeader font-normal`}
          className={`text-standard blackText printHide tableCell justify-content-center`}
          footerClassName="tableFooter"
        />
        <Column
          body={(record, options) => {
            if (!record.empID) {
              return;
            }

            return (
              <ExpandIcon
                expanded={expandedRows[record.empID]}
                onOpen={() => {
                  setExpandedRows((rows) => {
                    rows[record.empID] = true;

                    return { ...rows };
                  });
                }}
                onClose={() => {
                  setExpandedRows((rows) => {
                    delete rows[record.empID];

                    return { ...rows };
                  });
                }}
              />
            );
          }}
          style={{ minWidth: '30px', maxWidth: '30px' }}
          headerClassName={`tableHeader font-normal`}
          className={`text-standard blackText printHide tableCell justify-content-center`}
          footerClassName="tableFooter"
        />
        <Column
          body={(record, options) => {
            if (
              empData.length === 1 ||
              empData.length === options.rowIndex + 1
            ) {
              return;
            }

            return (
              <i
                className="pi pi-trash text-17px cursor-pointer"
                onClick={() => {
                  setEmpData((data) => {
                    const newData = data.filter(
                      (data, index) => options.rowIndex !== index
                    );

                    return newData;
                  });
                }}
              />
            );
          }}
          style={{ maxWidth: '30px' }}
          headerClassName={`tableHeader font-normal`}
          className={`text-standard blackText printHide tableCell justify-content-center`}
          footerClassName="tableFooter"
        />
      </Table>
      <div className="mt-3 flex flex-wrap justify-content-center gap-4 w-fit ml-auto mr-3 printHide">
        <PCReimbursementProcess
          draft={empData}
          approvalMode={!!approvalMode}
          defaultDraftID={draftID}
          isSubmitted={draftIsSubmitted}
          afterCheck={(show) => {
            setShowErrors(show);
          }}
          afterUpdate={updateAttachments}
          access={access}
        />
      </div>
    </React.Fragment>
  );
};

export default PCReimbursementTable;
