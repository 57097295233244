import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom'; 
import { ReimbursementFilters } from './PCReimbursementFilters';

const PCReimbursementParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setValue } = useFormContext<ReimbursementFilters>();
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    let value = searchParams.get('submittedID');
    if (value) {
      setValue('submittedID', value);
      setSearchParams();
    }

    setRendered(true);
  }, [setValue, searchParams, setSearchParams, rendered, setRendered]);

  return <span />;
};

export default PCReimbursementParams;
