import { Column } from 'primereact/column';
import {
  DataTableExpandedRows,
  DataTableRowMouseEventParams,
} from 'primereact/datatable';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Table from '../../../components/Table/Table';
import {
  EmployeeTimesheet,
  PayrollTimesheetDetail,
} from '../../../Interfaces/Accounting/ACForms.interfaces';
import { animateCellText } from 'apps/tmr-frontend/src/utils/htmlUtils';
import { InputNumber } from 'primereact/inputnumber';
import TimesheetDetailsTable from './TimeSheetDetailsTable';
import { formatCurrency } from 'apps/tmr-frontend/src/utils/formatUtils';
import JobInput from './JobInput';
import PhaseCodeInput from './PhaseCodeInput';
import ExpandIcon from './ExpandIcon';
import TimesheetProcess from './TImesheetProcess';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { Toast } from 'primereact/toast';
import {
  addDetailPerDateRange,
  addNewDetail,
  changeTimesheetDate,
  modifyTimesheetValue,
  removeDetail,
} from './util';
import CalendarIcon from './CalendarIcon';
import { Access } from '../../../Interfaces/Role.interfaces';
import { Tooltip } from 'primereact/tooltip';

type TimesheetTableProps = {
  employees: EmployeeTimesheet[];
  periodStart: Date;
  periodEnd: Date;
  draftID?: string;
  draftIsSubmitted?: boolean;
  approvalMode?: boolean;
  access: Access;
};

const TimesheetTable = ({
  employees,
  periodStart,
  periodEnd,
  draftID,
  draftIsSubmitted,
  approvalMode,
  access,
}: TimesheetTableProps) => {
  const [data, setData] = useState<EmployeeTimesheet[]>(employees);
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>({});
  const toast = useRef<Toast>(null);

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const updateAcc = (
    acc: Record<string, number>,
    hours: PayrollTimesheetDetail
  ) => {
    acc['reg'] += hours.regularHrs ?? 0;
    acc['overtime'] += hours.overTimeHrs ?? 0;
    acc['sick'] += hours.sickHrs ?? 0;
    acc['holiday'] += hours.holidayHrs ?? 0;
    acc['perDiem'] += hours.perDiem ?? 0;
  };

  const changeTimesheetValue = (
    empID: string,
    key: keyof PayrollTimesheetDetail,
    value?: string | number,
    detailID?: string,
    dateDetailID?: string
  ) => {
    const result = modifyTimesheetValue(
      empID,
      data,
      key,
      value,
      detailID,
      dateDetailID
    );

    if (result.error) {
      showToast('error', toast, 'Payroll Timesheet', result.error, 3000);
    }

    if (result.data) {
      setData(result.data);
    }
  };

  const changeDateDetails = (empID: string, detailID: string, date?: Date) => {
    const newData = changeTimesheetDate(data, empID, detailID, date);

    if (newData) {
      setData(newData);
    }
  };

  const addDetailRow = (empID: string, detailID?: string) => {
    const newData = addNewDetail(data, empID, detailID);

    if (newData) {
      setData(newData);
    }
  };

  const removeDetailRow = (
    empID: string,
    detailID: string,
    dateDetailID?: string
  ) => {
    const newData = removeDetail(data, empID, detailID, dateDetailID);

    if (newData) {
      setData(newData);
    }
  };

  const rowExpansionTemplate = (data: EmployeeTimesheet) => {
    return (
      <TimesheetDetailsTable
        details={data.details ?? []}
        empID={data.empID}
        changeDateDetails={changeDateDetails}
        changeTimesheetValue={changeTimesheetValue}
        addDetailRow={addDetailRow}
        removeDetailRow={removeDetailRow}
        maxDate={periodEnd}
        minDate={periodStart}
      />
    );
  };

  const footerData = data.reduce(
    (acc, employee) => {
      acc['total'] += employee.totalHrs ?? 0;

      if (employee.useDetails) {
        const details = employee.details ?? [];

        details.forEach((detail) => {
          if (detail.useDateDetails) {
            const dateDetails = detail.dateDetails ?? [];

            dateDetails.forEach((dateDetail) => {
              updateAcc(acc, dateDetail);
            });
          } else {
            updateAcc(acc, detail);
          }
        });
      } else {
        updateAcc(acc, employee);
      }

      return acc;
    },
    { total: 0, reg: 0, overtime: 0, sick: 0, holiday: 0, perDiem: 0 }
  );

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <Table
        id="pr-timesheet"
        data={data}
        dataKey="empID"
        expandedRows={expandedRows}
        rowExpansionTemplate={rowExpansionTemplate}
        className={`mx-3 dashboardOptionShadow with-expanded noPadding noHover`}
        onRowMouseEnter={useCallback(onRowHover, [])}
        fixedDefaultHeight={790}
      >
        <Column
          field="empID"
          header="Emp ID"
          style={{ minWidth: '100px' }}
          headerClassName="tableHeader font-normal text-center"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="lastName"
          header="Last Name"
          body={(pr) => {
            return <div className="scroll-text">{pr.lastName}</div>;
          }}
          style={{ minWidth: '180px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className=" text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="firstName"
          header="First Name"
          body={(pr) => {
            return <div className="scroll-text">{pr.firstName}</div>;
          }}
          style={{ minWidth: '160px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
          footer="Total"
          sortable
        />
        <Column
          field="totalHrs"
          header="Total Hrs"
          body={(record) => {
            if (record.totalHrs === undefined || record.totalHrs === null) {
              return;
            }

            return record.totalHrs;
          }}
          style={{ minWidth: '100px' }}
          headerClassName="tableHeader font-normal"
          className="text-standard blackText tableCell justify-content-center"
          footerClassName="tableFooter block text-center border-top-2 mt-4"
          footer={footerData['total']}
          sortable
        />
        <Column
          header="Dates"
          body={(record) => {
            if (expandedRows[record.empID]) {
              return;
            }

            if (record.useDetails) {
              return (
                <div className="mx-auto printHide">Expand for details</div>
              );
            }

            return;
          }}
          style={{ minWidth: '120px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
        />
        <Column
          field="regularHrs"
          header="REG Hrs"
          body={(record) => {
            return (
              <span className="text-center w-full">
                <InputNumber
                  className="printHide"
                  inputClassName="w-full blackText text-standard text-center"
                  value={record.regularHrs}
                  onBlur={(event) => {
                    changeTimesheetValue(
                      record.empID,
                      'regularHrs',
                      parseFloat(event.target.value?.replace(/,/g, '')) ??
                        undefined
                    );
                  }}
                  disabled={record.useDetails}
                  maxFractionDigits={3}
                />
                <span className="printMedia printShow">
                  {record.regularHrs}
                </span>
              </span>
            );
          }}
          style={{ minWidth: '100px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell"
          footerClassName="tableFooter block text-center border-top-2 mt-4"
          footer={footerData['reg']}
        />
        <Column
          field="overTimeHrs"
          header="OT Hrs"
          body={(record) => {
            return (
              <span className="text-center w-full">
                <InputNumber
                  className="printHide"
                  inputClassName="w-full blackText text-standard text-center"
                  value={record.overTimeHrs}
                  onBlur={(event) => {
                    changeTimesheetValue(
                      record.empID,
                      'overTimeHrs',
                      parseFloat(event.target.value?.replace(/,/g, '')) ??
                        undefined
                    );
                  }}
                  disabled={record.useDetails}
                  maxFractionDigits={3}
                />
                <span className="printMedia printShow">
                  {record.overTimeHrs}
                </span>
              </span>
            );
          }}
          style={{ minWidth: '100px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell"
          footerClassName="tableFooter block text-center border-top-2 mt-4"
          footer={footerData['overtime']}
        />
        <Column
          field="sickHrs"
          header="Sick Hrs"
          body={(record) => {
            return (
              <span className="text-center w-full">
                <InputNumber
                  className="printHide"
                  inputClassName="w-full blackText text-standard text-center"
                  value={record.sickHrs}
                  onBlur={(event) => {
                    changeTimesheetValue(
                      record.empID,
                      'sickHrs',
                      parseFloat(event.target.value?.replace(/,/g, '')) ??
                        undefined
                    );
                  }}
                  disabled={record.useDetails}
                  maxFractionDigits={3}
                />
                <span className="printMedia printShow">{record.sickHrs}</span>
              </span>
            );
          }}
          style={{ minWidth: '100px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell"
          footerClassName="tableFooter block text-center border-top-2 mt-4"
          footer={footerData['sick']}
        />
        <Column
          field="holidayHrs"
          header="Holiday Hrs"
          body={(record) => {
            return (
              <span className="text-center w-full">
                <InputNumber
                  className="printHide"
                  inputClassName="w-full blackText text-standard text-center"
                  value={record.holidayHrs}
                  onBlur={(event) => {
                    changeTimesheetValue(
                      record.empID,
                      'holidayHrs',
                      parseFloat(event.target.value?.replace(/,/g, '')) ??
                        undefined
                    );
                  }}
                  disabled={record.useDetails}
                  maxFractionDigits={3}
                />
                <span className="printMedia printShow">
                  {record.holidayHrs}
                </span>
              </span>
            );
          }}
          style={{ minWidth: '100px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell"
          footerClassName="tableFooter block text-center border-top-2 mt-4"
          footer={footerData['holiday']}
        />
        <Column
          field="perDiem"
          header="Per Diem"
          body={(record) => {
            return (
              <span className="text-right w-full">
                <InputNumber
                  className="printHide"
                  inputClassName="w-full blackText text-standard text-right"
                  min={0}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  value={record.perDiem}
                  onBlur={(event) => {
                    changeTimesheetValue(
                      record.empID,
                      'perDiem',
                      parseFloat(
                        event.target.value?.replace('$', '').replace(/,/g, '')
                      ) ?? undefined
                    );
                  }}
                  disabled={record.useDetails}
                />
                <span className="printMedia printShow">
                  {formatCurrency(record.perDiem)}
                </span>
              </span>
            );
          }}
          style={{ minWidth: '100px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(footerData['perDiem'])}
        />
        <Column
          field="jobNumber"
          header="Job Number"
          body={(record) => {
            if (expandedRows[record.empID]) {
              return;
            }

            if (record.useDetails) {
              return (
                <div className="mx-auto printHide">Expand for details</div>
              );
            }

            return (
              <JobInput
                value={record.job}
                onChange={(event) => {
                  changeTimesheetValue(record.empID, 'job', event.value);

                  if (record.phaseCode) {
                    changeTimesheetValue(record.empID, 'phaseCode', undefined);
                  }
                }}
              />
            );
          }}
          style={{ minWidth: '200px', maxWidth: '200px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell"
          footerClassName="tableFooter"
        />
        <Column
          field="phaseCode"
          header="Phase Code"
          body={(record) => {
            if (
              !record.job ||
              record.job === 'N/A' ||
              expandedRows[record.empID]
            ) {
              return;
            }

            if (record.useDetails) {
              return (
                <div className="mx-auto printHide">Expand for details</div>
              );
            }

            return (
              <PhaseCodeInput
                value={record.phaseCode}
                onChange={(event) => {
                  changeTimesheetValue(record.empID, 'phaseCode', event.value);
                }}
                job={record.job}
              />
            );
          }}
          style={{ minWidth: '200px', maxWidth: '200px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell"
          footerClassName="tableFooter"
        />
        <Column
          header={() => {
            return (
              <i
                id="openDetails"
                className="pi pi-plus text-17px cursor-pointer"
                onClick={() => {
                  const expandedCopy = { ...expandedRows };
                  data.forEach((emp) => {
                    if (emp.useDetails) {
                      expandedCopy[emp.empID] = true;
                    }
                  });
                  setExpandedRows(expandedCopy);
                }}
                data-pr-position="left"
                data-pr-tooltip="Expand all details"
              />
            );
          }}
          body={(record) => {
            return (
              <ExpandIcon
                expanded={expandedRows[record.empID]}
                onOpen={() => {
                  setExpandedRows((rows) => {
                    rows[record.empID] = true;

                    return { ...rows };
                  });
                }}
                onClose={() => {
                  setExpandedRows((rows) => {
                    delete rows[record.empID];

                    return { ...rows };
                  });
                }}
              />
            );
          }}
          style={{ maxWidth: '30px' }}
          headerClassName={`tableHeader font-normal`}
          className={`text-standard blackText printHide tableCell justify-content-center`}
          footerClassName="tableFooter"
        />
        <Column
          header={() => {
            return (
              <i
                id="closeDetails"
                className="pi pi-minus text-17px cursor-pointer"
                onClick={() => {
                  setExpandedRows({});
                }}
                data-pr-position="left"
                data-pr-tooltip="Collapse all details"
              />
            );
          }}
          body={(record) => {
            return (
              <CalendarIcon
                showIcon={expandedRows[record.empID]}
                maxDate={periodEnd}
                minDate={periodStart}
                onChange={(e) => {
                  const datesList = e.value as Date[];

                  if (datesList.length === 2 && datesList[1] !== null) {
                    const newData = addDetailPerDateRange(
                      data,
                      record.empID,
                      datesList
                    );

                    if (newData) {
                      setData(newData);
                    }
                  }
                }}
              />
            );
          }}
          style={{ minWidth: '30px', maxWidth: '30px' }}
          headerClassName={`tableHeader font-normal`}
          className={`text-standard blackText printHide tableCell justify-content-center`}
          footerClassName="tableFooter"
        />
      </Table>
      <div className="mt-3 flex flex-wrap justify-content-center gap-4 w-fit ml-auto mr-3 printHide">
        <TimesheetProcess
          draft={data}
          periodStart={periodStart}
          periodEnd={periodEnd}
          approvalMode={!!approvalMode}
          defaultDraftID={draftID}
          isSubmitted={draftIsSubmitted}
          access={access}
        />
      </div>
      <Tooltip target={`#openDetails`} />
      <Tooltip target={`#closeDetails`} />
    </React.Fragment>
  );
};

export default TimesheetTable;
