import { Checkbox } from 'primereact/checkbox';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormControlledDropdown from '../../components/inputs/FormInputs/FormControlledDropdown';
import { AccessForm } from '../../Interfaces/Role.interfaces';
import { RegisterFormFields } from './RegisterForm';

type ReportOptionProps = {
  item: AccessForm;
  index: number;
};

const ReportOption = ({ item, index }: ReportOptionProps) => {
  const { control, setValue } = useFormContext<RegisterFormFields>();
  const defaultEdit = item.editOption ? item.editOption[0].value : undefined;

  return (
    <div
      key={item.report}
      className="text-standard flex gap-2 align-items-center mt-2 capitalize flex-wrap"
    >
      <div className="flex gap-2 align-items-center">
        <Controller
          render={({ field }) => (
            <Checkbox
              inputId={item.report}
              checked={field.value}
              inputRef={field.ref}
              onChange={(e) => {
                field.onChange(e.checked);
              }}
            />
          )}
          name={`access.${index}.selected`}
          control={control}
        />
        <label htmlFor={item.report} className="ml-1 mr-3">
          {item.label}
        </label>
      </div>
      <div className="ml-4 flex flex-column gap-2">
        {item.editOption && (
          <div className="flex gap-2 align-items-center">
            <label
              htmlFor={`access.${index}.editable`}
              className="text-standard block"
            >
              Edit
            </label>
            <FormControlledDropdown
              defaultValue={defaultEdit ? `${defaultEdit}` : undefined}
              formID={`access.${index}.editable`}
              options={item.editOption}
              labelField="label"
              valueField="value"
              placeholder="Select option"
              clearable={false}
              additionalChange={() => {
                setValue(`access.${index}.selected`, true);
              }}
            />
          </div>
        )}
        {item.notification && (
          <div className="flex gap-2 align-items-center">
            <Controller
              name={`access.${index}.shouldNotify`}
              control={control}
              defaultValue={undefined}
              render={({ field }) => (
                <Checkbox
                  inputId={item.report + '_sn'}
                  checked={field.value}
                  inputRef={field.ref}
                  onChange={(e) => {
                    field.onChange(e.checked);

                    if (e.checked) {
                      setValue(`access.${index}.selected`, true);
                    }
                  }}
                />
              )}
            />
            <label htmlFor={item.report + '_sn'} className="ml-1 mr-3">
              Notify users
            </label>
          </div>
        )}
        {item.approvalOption &&
          (Array.isArray(item.approvalOption) ? (
            <div className="flex gap-2 align-items-center">
              <label
                htmlFor={`access.${index}.shouldApprove`}
                className="text-standard block"
              >
                Approve Order
              </label>
              <FormControlledDropdown
                defaultValue={undefined}
                formID={`access.${index}.shouldApprove`}
                options={item.approvalOption}
                placeholder="Select step"
                clearable={true}
                additionalChange={() => {
                  setValue(`access.${index}.selected`, true);
                }}
              />
            </div>
          ) : (
            <div className="flex gap-2 align-items-center">
              <Controller
                name={`access.${index}.shouldApprove`}
                control={control}
                defaultValue={undefined}
                render={({ field }) => (
                  <Checkbox
                    inputId={item.report + '_sa'}
                    checked={field.value}
                    inputRef={field.ref}
                    onChange={(e) => {
                      field.onChange(e.checked);

                      if (e.checked) {
                        setValue(`access.${index}.selected`, true);
                      }
                    }}
                  />
                )}
              />
              <label htmlFor={item.report + '_sa'} className="ml-1 mr-3">
                Approve content
              </label>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ReportOption;
