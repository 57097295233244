import { Dialog } from 'primereact/dialog';
import React, { useRef, useState } from 'react';
import SingleFileInput from '../../../components/inputs/StyledInputs/SingleFileInput';
import {
  downloadFile,
  FILE_FORMATS,
  transformBToMB,
} from 'apps/tmr-frontend/src/utils/fileUtil';
import { useQuery } from '@tanstack/react-query';
import { fetchPCReimbursementEmpAttachment } from '../../../services/PayrollService';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { Toast } from 'primereact/toast';

type PCReimbursementFileProps = {
  prevAttachment?: File | { name: string; path: string };
  attachment?: File;
  totalLimit: number;
  onChange: (file: File | null) => void;
  empID: string;
  draftID?: string;
};

const PCReimbursementFile = ({
  prevAttachment,
  attachment,
  totalLimit,
  onChange,
  empID,
  draftID,
}: PCReimbursementFileProps) => {
  const [showNoteDialog, setShowNoteDialog] = useState(false);
  const toast = useRef<Toast>(null);

  const { isFetching, refetch } = useQuery({
    queryKey: ['getPCReimbursementEmpFile', draftID, empID],
    queryFn: ({ signal }) => {
      return fetchPCReimbursementEmpAttachment(draftID || '', empID, signal);
    },
    onSuccess: (response) => {
      downloadFile(response);
    },
    onError: () => {
      showToast(
        'error',
        toast,
        'Employee Attachment',
        "The attachment couldn't be downloaded",
        3000
      );
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const downloadAttachment = (
    attachment: File | { name: string; path: string }
  ) => {
    if (prevAttachment instanceof File) {
      try {
        const file = attachment as File;
        const url = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.href = url;
        link.download = file.name;

        link.click();

        URL.revokeObjectURL(url);
      } catch (e) {
        showToast(
          'error',
          toast,
          'Employee Attachment',
          "The attachment couldn't be downloaded",
          3000
        );
      }
    } else {
      refetch();
    }
  };

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <i
        onClick={() => {
          setShowNoteDialog(true);
        }}
        className={`pi ${
          prevAttachment || attachment ? 'pi-file-edit' : 'pi-file'
        } text-17px totalColor cursor-pointer`}
      />
      <Dialog
        header="Employee Attachment"
        visible={showNoteDialog}
        onHide={() => {
          setShowNoteDialog(false);
        }}
      >
        <div>
          {prevAttachment && (
            <div>
              The current attachment is:
              {isFetching ? (
                <ProgressSpinner className="w-1rem h-auto ml-4" />
              ) : (
                <span
                  className="ml-1 totalColor cursor-pointer"
                  onClick={() => downloadAttachment(prevAttachment)}
                >
                  {prevAttachment.name}
                </span>
              )}
              <div className="mt-2 mb-1">
                To replace the current attachment select a new one below:
              </div>
            </div>
          )}
          <SingleFileInput
            value={attachment}
            onChange={(file) => {
              onChange(file);
            }}
            acceptedFormats={FILE_FORMATS}
            totalLimit={totalLimit + (attachment?.size ?? 0)}
            totalLimitLabel={`${transformBToMB(
              totalLimit + (attachment?.size ?? 0)
            )} MB`}
            emptyMessage={`Upload new attachment. Allowed extensions: ${FILE_FORMATS.join(
              ', '
            )}.`}
          />
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default PCReimbursementFile;
