import React from 'react';
import { useWatch } from 'react-hook-form';
import { useCompanyContext } from '../../../context/CompanyContext';
import { useQuery } from '@tanstack/react-query';
import { fetchContractBillingFilters } from '../CBFilters';
import {
  getBillItemStatusLabel,
  getCBReportTypeName,
  getSubmittedDraft,
} from './CBReportUtils';
import {
  fecthCBContracts,
  getSubmitedProgressBilling,
} from '../../../services/ContractBillingsService';
import {
  getContractName,
  getContractNames,
} from 'apps/tmr-frontend/src/utils/reportUtils';

const CBReportHeader = () => {
  const { selectedCompany } = useCompanyContext();
  const filters = useWatch<fetchContractBillingFilters>();

  const { data } = useQuery({
    queryKey: ['getCBActiveContracts', selectedCompany],
    queryFn: ({ signal }) =>
      fecthCBContracts({ companyID: selectedCompany!.id, status: 1 }, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const submittedPBs = useQuery({
    queryKey: [
      'getSubmittedProgressBillings',
      selectedCompany,
      filters.contract,
    ],
    queryFn: ({ signal }) =>
      getSubmitedProgressBilling(
        selectedCompany!.id,
        filters.contract ?? '',
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const monthlyContracts = useQuery({
    queryKey: ['getMonthlyContracts', selectedCompany],
    queryFn: ({ signal }) => {
      return fecthCBContracts(
        { companyID: selectedCompany!.id, department: 1 },
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return (
    <div className="blackText printMedia printShow">
      <div className="text-30px text-center mt-5">{selectedCompany!.name}</div>
      <div className="text-28px text-center">Contract Billings Report</div>
      <div className="text-17px ml-3 mt-2">
        <span className="font-bold text-20px">Search criteria:</span>
        <div className="ml-3">
          <div>
            <span className="font-bold mr-1">Report Type:</span>
            {getCBReportTypeName(filters.reportType ?? '')}
          </div>
          {filters.contract && data && (
            <React.Fragment>
              <div>
                <span className="font-bold mr-1">Contract:</span>
                {filters.contract}
              </div>
              <div>
                <span className="font-bold mr-1">Contract Name:</span>
                {getContractName(filters.contract, data)}
              </div>
            </React.Fragment>
          )}
          {filters.itemsState && filters.reportType === 'contractProgress' && (
            <div>
              <span className="font-bold mr-1">Items State:</span>
              {getBillItemStatusLabel(filters.itemsState)}
            </div>
          )}
          {filters.submittedID && (
            <div>
              <span className="font-bold mr-1">Submitted Draft:</span>
              {getSubmittedDraft(filters.submittedID, submittedPBs.data)}
            </div>
          )}
          {filters.contracts && monthlyContracts.data && (
            <React.Fragment>
              <div>
                <span className="font-bold mr-1">Contracts:</span>
                {filters.contracts.join(', ')}
              </div>
              <div>
                <span className="font-bold mr-1">Contract Names:</span>
                {getContractNames(filters.contracts, monthlyContracts.data)}
              </div>
            </React.Fragment>
          )}
          {filters.year && filters.reportType === 'monthlyBilling' && (
            <div>
              <span className="font-bold mr-1">Year:</span>
              {new Date(filters.year).getFullYear()}
            </div>
          )}
          {filters.info && filters.reportType === 'contractProgress' && (
            <div>
              <span className="font-bold">Search term:</span> {filters.info}
            </div>
          )}
        </div>
      </div>
      <div className="ml-3 text-17px mb-5">
        <span className="font-bold mr-1">Created at:</span>
        {new Date().toLocaleString()}
      </div>
    </div>
  );
};

export default CBReportHeader;
