import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import {
  DataTablePFSEvent,
  DataTableRowMouseEventParams,
} from 'primereact/datatable';
import { useNavigate } from 'react-router-dom';
import Table from '../../components/Table/Table';
import { UsersData } from '../../Interfaces/User.interfaces';
import { animateCellText } from '../../../utils/htmlUtils';
import { useCallback } from 'react';
import { formatDate } from 'apps/tmr-frontend/src/utils/formatUtils';
import WrapperButton from '../../components/buttons/WrapperButton';

type UsersTableProps = {
  isFetching: boolean;
  isError: boolean;
  setPage: (page: number) => void;
  setRowsAmmount: (rows: number) => void;
  usersData?: UsersData;
  rowsAmount: number;
  page: number;
  backupData?: UsersData;
  backupPage: number;
  removeUser: (id: string) => void;
  addUser: (id: string) => void;
  selectedUsers: string[];
};

const UsersTable = ({
  isFetching,
  isError,
  setPage,
  setRowsAmmount,
  usersData,
  rowsAmount,
  page,
  backupData,
  backupPage,
  removeUser,
  addUser,
  selectedUsers,
}: UsersTableProps) => {
  const navigate = useNavigate();

  const onPage = (event: DataTablePFSEvent) => {
    setPage(event.page ?? 0);
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const calcHeight = (rows: number) => {
    const headerHeight = 32;
    const footerHeight = 61;
    const rowHeight = 36;
    return headerHeight + footerHeight + rows * rowHeight + 2;
  };

  return (
    <Table
      data={isError ? backupData?.users ?? [] : usersData?.users ?? []}
      first={(isError ? backupPage : page) * rowsAmount}
      className={`tableStyled tablePrint`}
      lazy
      paginator
      loading={isFetching}
      totalRecords={isError ? backupData?.totalUsers : usersData?.totalUsers}
      onPage={onPage}
      calcHeight={calcHeight}
      onRowsAmountChange={setRowsAmmount}
      onRowMouseEnter={useCallback(onRowHover, [])}
    >
      <Column
        header="Select"
        body={(user) => {
          return (
            <div className="capitalize">
              <Checkbox
                checked={selectedUsers.includes(user.id)}
                onChange={(e) => {
                  if (e.checked) {
                    addUser(user.id);
                  } else {
                    removeUser(user.id);
                  }
                }}
              />
            </div>
          );
        }}
        style={{ minWidth: '70px', maxWidth: '70px' }}
        headerClassName={`tableHeader font-normal`}
        className={`justify-content-center text-standard blackText tableCell`}
        footerClassName="tableFooter"
      />
      <Column
        field="firstName"
        header="Firstname"
        body={(user) => {
          return <div className="scroll-text">{user.firstName}</div>;
        }}
        style={{ minWidth: '150px', maxWidth: '300px' }}
        headerClassName={`tableHeader font-normal justify-content-center`}
        className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="lastName"
        header="Lastname"
        body={(user) => {
          return <div className="scroll-text">{user.lastName}</div>;
        }}
        style={{ minWidth: '150px', maxWidth: '300px' }}
        headerClassName={`tableHeader font-normal justify-content-center`}
        className={` text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="email"
        header="Email"
        body={(user) => {
          return <div className="scroll-text">{user.email}</div>;
        }}
        style={{ minWidth: '350px', maxWidth: '350px' }}
        headerClassName={`tableHeader font-normal justify-content-center`}
        className={` text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="type"
        header="User Type"
        style={{ minWidth: '140px' }}
        headerClassName={`tableHeader font-normal text-center`}
        className={`justify-content-center text-standard blackText tableCell capitalize`}
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="isAdmin"
        header="Admin"
        body={(user) => {
          return (
            <div className="capitalize">{user.isAdmin ? 'yes' : 'no'}</div>
          );
        }}
        style={{ minWidth: '50px' }}
        headerClassName={`tableHeader font-normal`}
        className={`justify-content-center text-standard blackText tableCell capitalize`}
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="roles"
        header="Roles"
        body={(user) => {
          return (
            <div className="capitalize scroll-text">
              {user.roles
                ?.map((role: { id: string; name: string }) => role.name)
                .join(', ')}
            </div>
          );
        }}
        style={{ minWidth: '150px', maxWidth: '250px' }}
        headerClassName={`tableHeader font-normal justify-content-center`}
        className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="lastAccess"
        header="Last Access"
        body={(user) => formatDate(user.lastAccess)}
        style={{ minWidth: '100px' }}
        headerClassName={`tableHeader font-normal`}
        className={`text-standard justify-content-center blackText tableCell`}
        footerClassName="tableFooter"
        sortable
      />
      <Column
        header="Edit"
        body={(user) => {
          return (
            <WrapperButton onClick={() => navigate(`${user.id}`)}>
              <i className="pi pi-user-edit text-20px" />
            </WrapperButton>
          );
        }}
        style={{ minWidth: '70px', maxWidth: '70px' }}
        headerClassName={`tableHeader font-normal`}
        className={`justify-content-center text-standard blackText tableCell`}
        footerClassName="tableFooter"
      />
    </Table>
  );
};

export default UsersTable;
