import { formatUTCDate } from '../../../utils/formatUtils';
import { Link } from 'react-router-dom';
import ResumeCard from '../../components/cards/ResumeCard';
import { SCScheduleDates } from '../../Interfaces/Accounting/Subcontracts.interfaces';
import React from 'react';
import ErrorMessage from '../../components/messages/ErrorMessage';
import { useQuery } from '@tanstack/react-query';
import { fetchSCScheduleDates } from '../../services/SubcontractsService';
import { useCompanyContext } from '../../context/CompanyContext';
import { ApiError } from '../../Interfaces/Generic.interfaces';

const DashboardSubcontracts = () => {
  const { selectedCompany } = useCompanyContext();

  const { data, isError, error } = useQuery({
    queryKey: ['getSCScheduleDates', selectedCompany],
    queryFn: ({ signal }) => fetchSCScheduleDates(selectedCompany!.id, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  if (isError) {
    return (
      <ResumeCard>
        <div className="relative pt-4 px-4 flex flex-column w-full">
          <Link to={`/accounting-dashboard/subcontracts`} className="w-fit">
            <div className="text-24px font-bold cursor-pointer totalColor hover:underline w-fit">
              Subcontracts
            </div>
          </Link>
          <div className="my-5 py-3">
            <ErrorMessage
              content={
                (error as ApiError)?.response?.data?.message ??
                'Failed to obtain data! Please try again later.'
              }
            />
          </div>
        </div>
      </ResumeCard>
    );
  }

  const { nextCheck, worksheetDue } = data;
  return (
    <ResumeCard>
      <div className="p-3 px-4 flex flex-column">
        <Link to={`/accounting-dashboard/subcontracts`} className="w-fit">
          <div className="text-24px font-bold cursor-pointer totalColor hover:underline">
            Subcontracts
          </div>
        </Link>
        <div className="flex flex-wrap gap-4 mt-3 pl-2 pb-3">
          {!nextCheck && !worksheetDue && (
            <div className="w-fit pt-2">
              <div className="p-3 py-4 text-15px">
                This company has no subcontract payment schedule.
              </div>
            </div>
          )}
          {nextCheck && worksheetDue && (
            <React.Fragment>
              <div>
                <div className="text-15px pl-1">Work Sheet Due</div>
                <div className="text-25px dateText mt-1">
                  {formatUTCDate(worksheetDue)}
                </div>
              </div>
              <div>
                <div className="text-15px pl-1">Check Date</div>
                <div className="text-25px dateText mt-1">
                  {formatUTCDate(nextCheck)}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </ResumeCard>
  );
};

export default DashboardSubcontracts;
