import axios from 'axios';
import { auth } from '../../firebase';

const apiUrl = process.env['NX_API_URL'];

export const fecthGLAccounts = async (
  companyID: string,
  startDigits?: number[],
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/glAccounts`, {
    signal,
    params: { companyID, startDigits },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  return response.data;
};
