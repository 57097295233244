import { useQuery } from '@tanstack/react-query';
import FormControlledCalendar from '../../../components/inputs/FormInputs/FormControlledCalendar';
import FormControlledDropdown from '../../../components/inputs/FormInputs/FormControlledDropdown';
import FormControlledInput from '../../../components/inputs/FormInputs/FormControlledInput';
import { useCompanyContext } from '../../../context/CompanyContext';
import {
  fetchContractLookUpInfo,
  fetchNewContract,
} from '../../../services/ACFormsService';
import FormControlledNumberInput from '../../../components/inputs/FormInputs/FormControlledNumberInput';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { ContractInfo } from '../../../Interfaces/Accounting/ACForms.interfaces';
import { EXCEMPTED_DEPARTMENT_COMPANIES } from '../formConsts';
import FormControlledInputMask from '../../../components/inputs/FormInputs/FormControlledInputMask';
import { Menu } from 'primereact/menu';
import { fetchLatestContracts } from '../../../services/ContractBillingsService';

const ContractForm = () => {
  const { selectedCompany } = useCompanyContext();
  const { setValue } = useFormContext<ContractInfo>();
  const department = useWatch({ name: 'department' });
  const menu = useRef<Menu>(null);
  const [contractSuggestions, setContractSuggestions] = useState([]);

  const contract = useQuery({
    queryKey: ['getNewContract', selectedCompany, department],
    queryFn: ({ signal }) =>
      fetchNewContract(
        selectedCompany!.id,
        EXCEMPTED_DEPARTMENT_COMPANIES.includes(selectedCompany!.id)
          ? null
          : department,
        signal
      ),
    onSuccess: (data) => {
      setValue('contract', data);
    },
    onError: () => {
      setValue('contract', undefined);
    },
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && department === '1'),
    refetchInterval: 120000,
  });

  const contractLabel = () => {
    if (contract.isFetching) {
      return 'Searching new contract number';
    }

    if (contract.isError) {
      return 'Error searching new contract number';
    }

    return '99-9999';
  };

  useQuery({
    queryKey: ['getLatestContracts', selectedCompany, department],
    queryFn: ({ signal }) => {
      setContractSuggestions([]);
      return fetchLatestContracts(selectedCompany!.id, 5, department, signal);
    },
    onSuccess: (data) => {
      const list = data.map((contr: { Contract: string }) => {
        return { label: contr.Contract, disabled: true };
      });
      setContractSuggestions(list);
    },
    onError: () => {
      setContractSuggestions([]);
    },
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && department !== '1'),
  });

  const { data, isLoading, isError } = useQuery({
    queryKey: ['getNewContractInfo', selectedCompany],
    queryFn: ({ signal }) =>
      fetchContractLookUpInfo(selectedCompany!.id, signal),
    onSuccess: (data) => {
      setValue('department', data.departments[0]?.Department);
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  useEffect(() => {
    setValue('contract', undefined);
  }, [department, setValue]);

  return (
    <div className="grid mx-3 justify-content-center">
      <div className="col-12 sm:col-6">
        <label htmlFor="contract" className="text-standard block mb-2">
          Next Available Contract
        </label>
        <FormControlledInputMask
          defaultValue=""
          placeholder={contractLabel()}
          disabled={!department || department === '1'}
          mask="99-9999"
          unmask={department === '1'}
          formID="contract"
          rules={{
            required: {
              value: true,
              message: 'Contract is a required field',
            },
          }}
          onFocus={(event) => {
            if (menu.current) {
              menu.current.toggle(event);
            }
          }}
          aria-controls="contract_sug"
          aria-haspopup
        />
        {contractSuggestions.length > 0 && (
          <Menu
            model={contractSuggestions}
            popup
            ref={menu}
            id="contract_sug"
          />
        )}
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="contractName" className="text-standard block mb-2">
          Contract Name
        </label>
        <FormControlledInput
          defaultValue=""
          formID="contractName"
          rules={{
            required: {
              value: true,
              message: 'Contract Name is a required field',
            },
          }}
          autocomplete="off"
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="department" className="text-standard block mb-2">
          Department
        </label>
        <FormControlledDropdown
          defaultValue=""
          formID="department"
          rules={{
            required: {
              value: true,
              message: 'Department is a required field',
            },
          }}
          options={data?.departments}
          labelField="Description"
          valueField="Department"
          placeholder={
            isError ? 'Failed to load departments!' : 'Select department'
          }
          clearable={false}
          isDisabled={isError}
          isLoading={isLoading}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="customer" className="text-standard block mb-2">
          Customer
        </label>
        <FormControlledDropdown
          formID="customer"
          rules={{
            required: {
              value: true,
              message: 'Customer is a required field',
            },
          }}
          options={data?.customers}
          labelField="name"
          valueField="accountNumber"
          placeholder={
            isError ? 'Failed to load customers!' : 'Select customer'
          }
          isDisabled={isError}
          isLoading={isLoading}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="retainage" className="text-standard block mb-2">
          Retainage Default
        </label>
        <FormControlledNumberInput
          defaultValue={10}
          formID="retainage"
          rules={{
            required: {
              value: true,
              message: 'Retainage Default is a required field',
            },
          }}
          suffix="%"
          min={0}
          max={100}
          maxFractionDigits={2}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="processGroup" className="text-standard block mb-2">
          Processing Group
        </label>
        <FormControlledDropdown
          formID="processGroup"
          rules={{
            required: {
              value: true,
              message: 'Processing Group is a required field',
            },
          }}
          options={[
            ...(data?.processGroups || []),
            { Description: 'TBD', ProcessGroup: 'TBD' },
          ]}
          labelField="Description"
          valueField="ProcessGroup"
          placeholder={
            isError
              ? 'Failed to load processing groups!'
              : 'Select processing group'
          }
          isDisabled={isError}
          isLoading={isLoading}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="startDate" className="text-standard block mb-2">
          Contract Date
        </label>
        <FormControlledCalendar
          formID="startDate"
          rules={{
            required: {
              value: true,
              message: 'Start Date is a required field',
            },
          }}
          readOnlyInput
          showButtonBar
          className="w-full"
          triggerOnChange={true}
        />
      </div>
      <div className="col-12 sm:col-6" />
    </div>
  );
};

export default ContractForm;
