import { useQuery } from '@tanstack/react-query';
import { formatUTCDate } from 'apps/tmr-frontend/src/utils/formatUtils';
import FormControlledDropdown from '../../../components/inputs/FormInputs/FormControlledDropdown';
import { useCompanyContext } from '../../../context/CompanyContext';
import { PRTimesheetSummary } from '../../../Interfaces/Accounting/ACForms.interfaces';
import { fetchPCReimburusements } from '../../../services/PayrollService';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';

export type ReimbursementFilters = {
  submittedID?: string;
};

const PCReimbursementFilters = () => {
  const { selectedCompany } = useCompanyContext();
  const { rolesAcess } = useRolesAccessContext();
  const canApprove = rolesAcess?.find(
    (access) =>
      access.report === 'petty_cash_reimbursement' && access.shouldApprove
  );

  const submittedPCReimburusements = useQuery({
    queryKey: ['getSubmittedPCReimburusements', selectedCompany],
    queryFn: ({ signal }) =>
      fetchPCReimburusements(
        {
          companyID: selectedCompany!.id,
          isSubmitted: true,
          isApproved: false,
        },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && canApprove),
    staleTime: 1000 * 60 * 10,
    cacheTime: 0,
  });

  return (
    <>
      {canApprove && (
        <div className="col">
          <FormControlledDropdown
            formID="submittedID"
            options={
              submittedPCReimburusements.data?.map(
                (draft: PRTimesheetSummary) => {
                  return {
                    ...draft,
                    label: `${formatUTCDate(draft.updatedAt || '')} - ${
                      draft.userName
                    }`,
                  };
                }
              ) || []
            }
            labelField="label"
            valueField="id"
            placeholder={
              submittedPCReimburusements.isError
                ? 'Failed to load submitted drafts!'
                : 'Approve Submitted Drafts'
            }
            isDisabled={
              submittedPCReimburusements.isError ||
              !submittedPCReimburusements.data?.length
            }
            isLoading={submittedPCReimburusements.isFetching}
            clearable={true}
            filter={true}
            resetFilterOnHide={true}
          />
        </div>
      )}
    </>
  );
};

export default PCReimbursementFilters;
