import {
  FileUpload,
  FileUploadHeaderTemplateOptions,
  FileUploadProps,
  FileUploadRemoveParams,
  FileUploadSelectParams,
  ItemTemplateOptions,
} from 'primereact/fileupload';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ErrorToast, { showToast } from '../../messages/ErrorAlert';
import { Toast } from 'primereact/toast';
import FileInputHeader from './../FileComponents/FileInputHeader';
import FileInputItem from './../FileComponents/FileInputItem';

export type MultipleFileInputProps = FileUploadProps & {
  acceptedFormats: string[];
  contentClassName?: string;
  value: File[];
  onChange: (files: File[] | null) => void;
  totalLimit: number;
  totalLimitLabel: string;
  emptyMessage?: string;
};

const MultipleFileInput = ({
  acceptedFormats,
  contentClassName,
  value,
  onChange,
  totalLimit,
  totalLimitLabel,
  emptyMessage,
  ...otherProps
}: MultipleFileInputProps) => {
  const fileUploadRef = useRef<FileUpload>(null);
  const [sizeTotal, setSizeTotal] = useState(0);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const toast = useRef<Toast>(null);

  const onSelect = (data: FileUploadSelectParams) => {
    const length = data.files.length;
    let startSize = sizeTotal;

    const startList = [...(value || [])];
    const filesOverTheLimit = [];
    for (let i = 0; i < length; i++) {
      const selFile = data.files[i];
      const repeated = startList.filter(
        (file: File) =>
          file.name === selFile.name &&
          file.size === selFile.size &&
          file.type === selFile.type &&
          file.lastModified === selFile.lastModified
      );

      if (startSize + selFile.size <= totalLimit && repeated.length === 0) {
        startSize += selFile.size;
        startList.push(selFile);
      } else if (startSize + selFile.size > totalLimit) {
        filesOverTheLimit.push(selFile.name);
      }
    }

    setSizeTotal(startSize);
    onChange(startList);
    setFileNames(startList.map((file) => file.name));

    if (filesOverTheLimit.length > 0) {
      showToast(
        'error',
        toast,
        'Upload File Error',
        `The following files are over the limit: ${filesOverTheLimit.join(
          ', '
        )}`,
        5000
      );
    }
  };

  const onRemove = (data: FileUploadRemoveParams) => {
    const removed = value?.filter(
      (file: File) =>
        !(
          file.name === data.file.name &&
          file.size === data.file.size &&
          file.type === data.file.type &&
          file.lastModified === data.file.lastModified
        )
    );
    onChange(removed?.length > 0 ? removed : null);
    setSizeTotal(sizeTotal - data.file.size);
    setFileNames(value?.map((file: File) => file.name) || []);

    if (!(sizeTotal - data.file.size)) {
      fileUploadRef.current?.clear();
    }
  };

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    return (
      <FileInputHeader
        headerOptions={options}
        currentSize={sizeTotal}
        sizeLimitLabel={totalLimitLabel}
        sizelimit={totalLimit}
      />
    );
  };

  const itemTemplate = (file: File, props: ItemTemplateOptions) => {
    const isInValue = fileNames.includes(file.name);

    return isInValue && <FileInputItem file={file} onRemove={props.onRemove} />;
  };

  useEffect(() => {
    if (!value || value?.length === 0) {
      fileUploadRef.current?.clear();
      setSizeTotal(0);
      setFileNames([]);
    }
  }, [value]);

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <FileUpload
        ref={fileUploadRef}
        accept={acceptedFormats.join(',')}
        contentClassName={contentClassName}
        multiple={true}
        headerTemplate={useCallback(headerTemplate, [
          sizeTotal,
          totalLimit,
          totalLimitLabel,
        ])}
        itemTemplate={(file, props) => itemTemplate(file as File, props)}
        emptyTemplate={
          <p className="m-0">
            {emptyMessage ?? (
              <React.Fragment>
                <span className="mr-1">
                  Drag and drop files to upload. Allowed extensions:
                </span>
                {acceptedFormats.join(', ')}
              </React.Fragment>
            )}
          </p>
        }
        cancelOptions={{ className: 'hidden' }}
        uploadOptions={{ className: 'hidden' }}
        onSelect={useCallback(onSelect, [
          onChange,
          sizeTotal,
          value,
          totalLimit,
        ])}
        onRemove={useCallback(onRemove, [onChange, value, sizeTotal])}
        {...otherProps}
      />
    </React.Fragment>
  );
};

export default MultipleFileInput;
