import { useMutation } from '@tanstack/react-query';
import { arrayComparison } from 'apps/tmr-frontend/src/utils/utils';
import { AxiosError } from 'axios';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import RejectionField from '../../../components/dialog/RejectionField';
import LoadingButton from '../../../components/inputs/LoadingButton';
import ConfirmationDialog from '../../../components/messages/ConfirmationDialog';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { useCompanyContext } from '../../../context/CompanyContext';
import {
  EmployeePCReimbursement,
  PCReimbursementArgs,
  PCReimbursementDetails,
} from '../../../Interfaces/Accounting/Payroll.interface';
import {
  postPCReimbursementDraft,
  putPCReimbursementDraft,
  putPCReimbursementSubmitDraft,
  putReimbursementApproveDraft,
  putReimbursementRejectDraft,
} from '../../../services/PayrollService';
import { Access } from '../../../Interfaces/Role.interfaces';

type PCReimbursementProcessProps = {
  approvalMode: boolean;
  draft: EmployeePCReimbursement[];
  defaultDraftID?: string;
  isSubmitted?: boolean;
  blockAll?: boolean;
  afterCheck: (showErrors: boolean) => void;
  afterUpdate: () => void;
  access: Access;
};

const PCReimbursementProcess = ({
  defaultDraftID,
  approvalMode,
  isSubmitted,
  draft,
  blockAll,
  afterCheck,
  afterUpdate,
  access,
}: PCReimbursementProcessProps) => {
  const { selectedCompany } = useCompanyContext();
  const toast = useRef<Toast>(null);
  const [draftID, setDraftID] = useState<string>(defaultDraftID ?? '');
  const [blockSave, setBlockSave] = useState(true);
  const [blockSubmit, setBlockSubmit] = useState(
    isSubmitted ?? !defaultDraftID
  );
  const [blockApproval, setBlockApproval] = useState(
    approvalMode ? false : !defaultDraftID
  );
  const [hasBeenApproved, setHasBeenApproved] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const prevDraft = useRef(draft);

  const save = useMutation({
    mutationFn: ({
      draftID,
      draft,
    }: {
      draftID: string;
      draft: PCReimbursementArgs;
    }) => {
      if (draftID) {
        return putPCReimbursementDraft(draftID, draft, !!approvalMode);
      }

      return postPCReimbursementDraft(selectedCompany!.id, draft);
    },
    onSuccess: (data) => {
      showToast(
        'success',
        toast,
        'Save Petty Cash Reimbursement',
        'The data was saved successfully!',
        3000
      );

      afterUpdate();

      setBlockSave(true);
      setBlockSubmit(false);
      setBlockApproval(false);

      if (!draftID) {
        setDraftID(data.id);
      }
    },
    onError: () => {
      showToast(
        'error',
        toast,
        'Save Petty Cash Reimbursement',
        "The data couldn't be saved",
        3000
      );
    },
  });

  const checkDraft = (draft: EmployeePCReimbursement[]) => {
    let complete = true;
    const hasEmptyValue = (detail: PCReimbursementDetails) => {
      return (
        (!detail.job && !detail.overHead) || (detail.job && !detail.phaseCode)
      );
    };
    console.log(draft);
    draft.forEach((emp) => {
      if (!emp.empID) {
        console.log('return null');
        return;
      }

      if (emp.useDetails) {
        const { details } = emp;

        details?.forEach((detail) => {
          if (hasEmptyValue(detail)) {
            complete = false;
            console.log('false details');
          }
        });
      } else {
        if (hasEmptyValue(emp)) {
          complete = false;

          console.log('false main row');
        }
      }
    });

    return complete;
  };

  const saveDraft = (draftID: string, draft: EmployeePCReimbursement[]) => {
    const processedDraft = draft.reduce((acc, emp) => {
      if (!emp.empID) {
        return acc;
      }

      const empCopy = {
        ...emp,
        empID: emp.empID,
        total: emp.total || 0,
        attachment: emp.newAttachment,
      };
      if (!empCopy.useDetails) {
        delete empCopy.details;
      }

      delete empCopy.useDetails;
      delete empCopy.firstName;
      delete empCopy.lastName;
      delete empCopy.newAttachment;

      acc.push(empCopy);

      return acc;
    }, [] as PCReimbursementArgs);

    save.mutate({ draftID, draft: processedDraft });
  };

  const submit = useMutation({
    mutationFn: (draftID: string) => {
      return putPCReimbursementSubmitDraft(draftID);
    },
    onSuccess: () => {
      setBlockSubmit(true);

      showToast(
        'success',
        toast,
        'Submit Petty Cash Reimbursement',
        'The data was submitted successfully!',
        3000
      );
    },
    onError: () => {
      showToast(
        'error',
        toast,
        'Submit Petty Cash Reimbursement',
        "The data couldn't be submitted",
        3000
      );
    },
  });

  const approveRequest = useMutation({
    mutationFn: (draftID: string) => {
      return putReimbursementApproveDraft(draftID);
    },
    onSuccess: () => {
      const label = approvalMode ? 'Approve' : 'Upload';
      const mesLabel = approvalMode ? 'approved' : 'uploaded';

      showToast(
        'success',
        toast,
        `${label} Petty Cash Reimbursement`,
        `The draft was ${mesLabel} successfully!`,
        3000
      );

      setBlockApproval(true);
      setHasBeenApproved(true);
    },
    onError: (error: AxiosError) => {
      const label = approvalMode ? 'Approve' : 'Upload';
      const mesLabel = approvalMode ? 'approved' : 'uploaded';

      const errorData = error.response?.data as {
        code: string;
        message: string;
      };
      showToast(
        'error',
        toast,
        `${label} Petty Cash Reimbursement`,
        errorData?.message || `The draft couldn't be ${mesLabel}`,
        3000
      );
    },
  });

  const rejectRequest = useMutation({
    mutationFn: (props: { draftID: string; reason: string }) => {
      return putReimbursementRejectDraft(props.draftID, props.reason);
    },
    onSuccess: () => {
      showToast(
        'success',
        toast,
        `Reject Petty Cash Reimbursement`,
        `The draft was rejected successfully!`,
        3000
      );

      setBlockApproval(true);
      setHasBeenApproved(true);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as {
        code: string;
        message: string;
      };

      showToast(
        'error',
        toast,
        `Reject Petty Cash Reimbursement`,
        errorData?.message || `The draft couldn't be rejected`,
        3000
      );
    },
  });

  const formErrorMessage = (text: string) => {
    showToast('error', toast, `Petty Cash Reimbursement`, text, 3000);
  };

  useEffect(() => {
    if (prevDraft.current !== draft && !hasBeenApproved) {
      const areEquals = arrayComparison(prevDraft.current, draft);

      if (!areEquals) {
        setBlockSave(false);
        setBlockSubmit(true);
        setBlockApproval(true);
        prevDraft.current = draft;
      }
    }
  }, [draft, hasBeenApproved]);

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <LoadingButton
        label="Save"
        fontSize="text-xl"
        bgColor="buttonSecondary"
        isLoading={save.isLoading}
        onClick={() => saveDraft(draftID, draft)}
        disabled={blockAll || blockSave || save.isLoading}
      />
      {!approvalMode && access.editable === 'approval' && (
        <ConfirmationDialog
          Button={
            <LoadingButton
              label="Submit"
              fontSize="text-xl"
              isLoading={submit.isLoading}
              disabled={blockAll || blockSubmit || submit.isLoading}
              type="button"
            />
          }
          onConfirm={() => {
            if (checkDraft(draft)) {
              submit.mutate(draftID);
              afterCheck(false);
            } else {
              afterCheck(true);
              formErrorMessage('The form is missing some fields!');
            }
          }}
          message="Are you sure you want to submit this draft?"
        />
      )}
      {approvalMode && access.shouldApprove && (
        <ConfirmationDialog
          tagKey="draft-rejection"
          visibility={visibility}
          Button={
            <LoadingButton
              label={'Reject'}
              fontSize="text-xl"
              bgColor="bluwaiRed"
              isLoading={rejectRequest.isLoading}
              disabled={blockAll || blockApproval || rejectRequest.isLoading}
              type="button"
              onClick={() => setVisibility(true)}
            />
          }
          contentClassName="pb-0"
          onConfirm={() => {}}
          acceptClassName="hidden"
          rejectClassName="hidden"
          onHide={() => setVisibility(false)}
          message={
            <RejectionField
              onReject={() => {
                setVisibility(false);
              }}
              onConfirm={(reason) => {
                rejectRequest.mutate({ draftID, reason });
                setVisibility(false);
              }}
            />
          }
        />
      )}
      {(approvalMode
        ? access.shouldApprove
        : access.editable === 'no-approval') && (
        <ConfirmationDialog
          tagKey="draft-approval"
          Button={
            <LoadingButton
              label={approvalMode ? 'Approve' : 'Upload'}
              fontSize="text-xl"
              bgColor="bg-green-500"
              isLoading={approveRequest.isLoading}
              disabled={blockAll || blockApproval || approveRequest.isLoading}
              type="button"
            />
          }
          onConfirm={() => {
            if (checkDraft(draft)) {
              approveRequest.mutate(draftID);
              afterCheck(false);
            } else {
              afterCheck(true);
              formErrorMessage('The form is missing some fields!');
            }
          }}
          message={`Are you sure you want to ${
            approvalMode ? 'approve' : 'upload'
          } this draft?`}
        />
      )}
    </React.Fragment>
  );
};

export default PCReimbursementProcess;
