import { BORDER_COLORS } from '../../../../utils/graphConst';
import React, { useEffect, useState } from 'react';
import { FolderSummary } from '../../../Interfaces/Accounting/FinancialStatements.interface';
import FolderOption from './Folder';
import NewFolderOption from '../NewFolder/NewFolderOption';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';

type FolderListProps = {
  folders: FolderSummary[];
  fetchFolders: () => void;
};

const FolderList = ({ folders, fetchFolders }: FolderListProps) => {
  const { rolesAcess } = useRolesAccessContext();

  const canEdit = rolesAcess?.find(
    (acess) => acess.report === 'financial_statements' && acess.editable
  );
  const [folderList, setFolderList] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const list = folders.map((folder, idx) => (
      <div key={folder.id} className="col flex justify-content-center">
        <FolderOption
          folder={folder}
          color={BORDER_COLORS[idx % BORDER_COLORS.length]}
          fetchFolders={fetchFolders}
        />
      </div>
    ));

    for (let i = 0; i < 7; i++) {
      list.push(
        <div key={i} className="col">
          <div className="w-15rem" />
        </div>
      );
    }

    setFolderList(list);
  }, [folders, fetchFolders]);

  return (
    <div className="grid">
      {canEdit && (
        <div className="col flex justify-content-center">
          <NewFolderOption afterCreate={fetchFolders} />
        </div>
      )}
      {folderList}
    </div>
  );
};

export default FolderList;
