import { Access } from './Role.interfaces';

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  companyID: string[];
  roles: { id: string; name: string }[];
  isAdmin: boolean;
  type: string;
  vpUser?: string;
  lastAccess?: Date;
  access?: Access[];
  securityGroups?: number[];
};

export type UserSummary = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
};

export type UsersData = {
  users: UserSummary[];
  totalUsers: number;
};

export enum CompanySource {
  ViewPoint = 'ViewPoint',
  Yardi = 'Yardi',
}

export type Company = {
  source: CompanySource;
  id: string;
  code?: string;
  alias?: string;
  name: string;
  address: string;
  address2: string;
  phone: string;
  city: string;
  state: string;
  zip: string;
  udAbbrev: string;
};

export type USFilterFields = 'info' | 'roles' | 'userType' | 'isAdmin';

export type VPUser = {
  VPUserName: string;
  FullName: string;
  Email?: string;
};
