import React from 'react';
import ReportButton from '../../../components/report/ReportButton';
import * as XLSX from 'xlsx';
import { useCompanyContext } from '../../../context/CompanyContext';
import { useWatch } from 'react-hook-form';
import { formatDate } from '../../../../utils/formatUtils';
import { fetchAPOpenJobs } from '../../../services/AccountsPayablesService';
import { useQuery } from '@tanstack/react-query';
import {
  getJobNames,
  getPayableTypeName,
  getVendorNames,
} from './APReportUtils';
import { BASE_PDF_STYLES } from '../../../../utils/reportUtils';
import { excelCleanTable } from '../../../../utils/excelUtils';
import { fetchAccountsPayablesFilters } from '../APFilters';
import { getVendorFetch, getVendorKeyPerCompany } from '../apUtil';

const idPerPayableType = {
  openPayablesPerJob: 'open-per-job-table',
  openPayables: 'open-table',
  agedPayables: 'aged-table',
  paymentHistory: 'payment-history-table',
  unapprovedInvoices: 'unapproved-table',
  invoiceArchive: 'open-table',
  vendorList: 'vendor-table',
};

type APReportProps = {
  elementRef: React.MutableRefObject<HTMLElement | null>;
};

const APReport = ({ elementRef }: APReportProps) => {
  const { selectedCompany } = useCompanyContext();
  const vendorKey = getVendorKeyPerCompany(selectedCompany!.source);
  const filters = useWatch<fetchAccountsPayablesFilters>();
  const reportDate = new Date();

  const { data } = useQuery({
    queryKey: ['getAPVendors', selectedCompany!.id, filters.reportType],
    queryFn: ({ signal }) => {
      return getVendorFetch(filters.reportType ?? '', selectedCompany!, signal);
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const jobs = useQuery({
    queryKey: ['getAPOpenJobs', selectedCompany],
    queryFn: ({ signal }) => {
      return selectedCompany ? fetchAPOpenJobs(selectedCompany, signal) : null;
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const paymentHistoryPreprocess = (tableBody: Element) => {
    const headers = tableBody.querySelectorAll('.p-datatable-row-expansion th');

    if (headers.length > 0) {
      Array.from(headers).forEach(function (header) {
        const td = document.createElement('td');
        const children = header.childNodes;

        Array.from(children).forEach(function (child) {
          td.append(child.textContent ?? '');
        });

        td.setAttribute('class', header.className);
        header.replaceWith(td);
      });
    }

    const expandedRows = tableBody.getElementsByClassName(
      'p-datatable-row-expansion'
    );
    Array.from(expandedRows).forEach(function (expandedRow) {
      const parent = expandedRow.parentElement;
      const rows = expandedRow.getElementsByTagName('tr');

      Array.from(rows).forEach(function (row) {
        const description = row.getElementsByClassName('doubleColumn')[0];
        description?.setAttribute('colspan', '2');
        parent?.insertBefore(row, expandedRow);
      });

      expandedRow.remove();
    });
  };

  const excelElementPreProcess = (element: HTMLElement) => {
    const tableBody = element.getElementsByClassName('p-datatable-tbody')[0];

    excelCleanTable(element);

    if (filters.reportType === 'paymentHistory') {
      paymentHistoryPreprocess(tableBody);
    }

    return element;
  };

  const agedPayablesBeforeDownload = (
    sheet: XLSX.WorkSheet,
    element?: HTMLElement
  ) => {
    const ref = sheet['!ref'];
    const boundary = (ref ?? '').replace(/[A-Z]/g, '').split(':');
    const lastRow = parseInt(boundary[1]);

    const totalPayables = element?.querySelector('#totalPayables');
    const totalRetainage = element?.querySelector('#totalRetainage');
    const totalDiscount = element?.querySelector('#totalDiscount');
    const grandTotal = element?.querySelector('#grandTotal');

    sheet[`I${lastRow + 2}`] = { t: 's', v: 'Accounts Payables:' };
    sheet[`J${lastRow + 2}`] = { t: 's', v: totalPayables?.textContent };
    sheet[`I${lastRow + 3}`] = { t: 's', v: 'A/P - Retainage:' };
    sheet[`J${lastRow + 3}`] = { t: 's', v: totalRetainage?.textContent };
    sheet[`I${lastRow + 4}`] = { t: 's', v: 'Discount:' };
    sheet[`J${lastRow + 4}`] = { t: 's', v: totalDiscount?.textContent };
    sheet[`I${lastRow + 5}`] = { t: 's', v: 'Grand Total:' };
    sheet[`J${lastRow + 5}`] = { t: 's', v: grandTotal?.textContent };

    sheet['!fullref'] = `A${1}:J${lastRow + 5}`;
    sheet['!ref'] = `A${1}:J${lastRow + 5}`;

    if (sheet['!cols']) {
      sheet['!cols'][8].width = 17;
    }
  };

  const paymentHistoryBeforeDownload = (sheet: XLSX.WorkSheet) => {
    const cols = sheet['!cols'];
    if (cols) {
      const widthTotal = (cols[3].width ?? 0) + (cols[4].width ?? 0);
      cols[3].width = widthTotal / 2;
      cols[4].width = widthTotal / 2;
    }

    const rows = sheet['!rows'];
    const ref = sheet['!ref'];
    const boundary = (ref ?? '').replace(/[A-Z]/g, '').split(':');
    const firstRow = parseInt(boundary[0]);
    const lastRow = parseInt(boundary[1]);

    for (let i = firstRow; i <= lastRow; i++) {
      const cell = sheet[`A${i}`];
      if (cell && !cell.v) {
        rows?.push({ level: 1 });
      } else {
        rows?.push({ level: 0 });
      }
    }
  };

  const excelBeforeDownload = (
    workBook: XLSX.WorkBook,
    element?: HTMLElement
  ) => {
    const sheet = workBook.Sheets['Sheet1'];
    const companyName = selectedCompany!.name;
    const searchTerm = filters.info;
    const dateSearch = filters.dates;

    sheet['D2'] = { t: 's', v: companyName };
    sheet['D3'] = { t: 's', v: 'Accounts Payables Report' };
    sheet['A4'] = { t: 's', v: 'Search criteria:' };
    sheet['B5'] = { t: 's', v: 'Report Type:' };
    sheet['C5'] = {
      t: 's',
      v: getPayableTypeName(filters.reportType ?? ''),
    };

    let rowStart = 6;

    if (filters.jobs && filters.jobs.length !== 0) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Jobs:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: filters.jobs.join(', '),
      };
      sheet[`B${rowStart + 1}`] = { t: 's', v: 'Job Names:' };
      sheet[`C${rowStart + 1}`] = {
        t: 's',
        v: getJobNames(filters.jobs, jobs.data),
      };
      rowStart += 2;
    }

    if (filters.vendors && filters.vendors.length !== 0) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Vendors:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: getVendorNames(filters.vendors, data, vendorKey),
      };
      rowStart += 1;
    }

    if (dateSearch) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Date range:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: `${formatDate(dateSearch[0])} ${
          dateSearch[1] ? ` - ${formatDate(dateSearch[1])}` : ''
        } `,
      };
      rowStart += 1;
    } else if (filters.reportType === 'paymentHistory') {
      sheet[`B${rowStart}`] = { t: 's', v: 'Month:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: new Date().toLocaleString('en-US', { month: 'long' }),
      };
      rowStart += 1;
    }

    if (searchTerm) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Search term:' };
      sheet[`C${rowStart}`] = { t: 's', v: searchTerm };
      rowStart += 1;
    }

    sheet[`A${rowStart}`] = { t: 's', v: 'Created at:' };
    sheet[`B${rowStart}`] = { t: 's', v: reportDate.toLocaleString() };

    if (filters.reportType === 'agedPayables') {
      agedPayablesBeforeDownload(sheet, element);
    } else if (filters.reportType === 'paymentHistory' && sheet['!cols']) {
      paymentHistoryBeforeDownload(sheet);
    }
  };

  return (
    <ReportButton
      elementToPrintRef={elementRef}
      fileName="AP_Report"
      tableOriginCell="A13"
      pdfStyles={BASE_PDF_STYLES}
      excelPreprocess={excelElementPreProcess}
      excelBeforeDownload={excelBeforeDownload}
      widthElementID={
        idPerPayableType[filters.reportType as keyof typeof idPerPayableType]
      }
    />
  );
};

export default APReport;
