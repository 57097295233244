import { useQuery } from '@tanstack/react-query';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import DropdownStyled from '../../../components/inputs/StyledInputs/DropdownStyled';
import FormErrorMessage from '../../../components/messages/FormErrorMessage';
import Table from '../../../components/Table/Table';
import { useCompanyContext } from '../../../context/CompanyContext';
import { GLAccount } from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import { JCJobSummary } from '../../../Interfaces/Accounting/JobsContracts.interface';
import { PCReimbursementDetails } from '../../../Interfaces/Accounting/Payroll.interface';
import { fecthGLAccounts } from '../../../services/GLAccountService';
import {
  fecthJobs,
  fetchJCJobPhases,
} from '../../../services/JobsContractsService';

type PCReimbursementTableDetailsProps = {
  details: PCReimbursementDetails[];
  empID: number;
  showErrors: boolean;
  addNewDetail: (empID: number) => void;
  removeDetail: (empID: number, detailIndex: number) => void;
  updateDetails: (
    empID: number,
    detailIndex: number,
    detail: PCReimbursementDetails
  ) => void;
};

const PCReimbursementTableDetails = ({
  details,
  empID,
  addNewDetail,
  removeDetail,
  updateDetails,
  showErrors,
}: PCReimbursementTableDetailsProps) => {
  const { selectedCompany } = useCompanyContext();

  const jobs = useQuery({
    queryKey: ['getPRJobs', selectedCompany],
    queryFn: ({ signal }) =>
      fecthJobs({ companyID: selectedCompany!.id, status: [1] }, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });
  const jobItems =
    jobs.data?.map((job: JCJobSummary) => {
      return { ...job, label: `${job.Job} ${job.Description}` };
    }) ?? [];

  const glAccounts = useQuery({
    queryKey: ['getPCRglAccounts', selectedCompany],
    queryFn: ({ signal }) =>
      fecthGLAccounts(selectedCompany!.id, [63, 64, 65, 66, 67, 68], signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });
  const glAccountsItems =
    glAccounts.data?.map((glAccount: GLAccount) => {
      return {
        ...glAccount,
        label: `${glAccount.GLAcct} ${glAccount.Description}`,
      };
    }) ?? [];

  return (
    <Table
      data={details}
      className={`expandedTable noFooter w-full`}
      scrollable={false}
      scrollHeight={undefined}
    >
      <Column
        style={{ minWidth: '100px' }}
        headerClassName="hidden"
        className="justify-content-center text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        body={() => {
          return <span className="visibility-hidden">details</span>;
        }}
        style={{ minWidth: '180px' }}
        headerClassName="hidden"
        className=" text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        style={{ minWidth: '160px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        style={{ minWidth: '100px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        field="amount"
        header="Amount"
        body={(row, options) => {
          return (
            <span className="text-center w-full">
              <InputNumber
                className="printHide"
                inputClassName="w-full blackText text-standard text-center"
                value={row.amount}
                onBlur={(e) => {
                  const rowCopy = { ...row };
                  if (e.target.value) {
                    const amount = parseFloat(
                      e.target.value?.replace(/,/g, '')
                    );
                    rowCopy.amount = amount;
                  } else {
                    delete rowCopy.amount;
                  }

                  updateDetails(empID, options.rowIndex, rowCopy);
                }}
                disabled={row.useDetails}
                maxFractionDigits={3}
              />
              <span className="printMedia printShow">{row.amount}</span>
            </span>
          );
        }}
        style={{ minWidth: '100px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        field="job"
        header="Job Number"
        body={(row, options) => {
          const selectedInfo = jobItems.find(
            (job: JCJobSummary) => job.Job === row.job
          );

          return (
            <div className="w-full">
              <span className="h-full">
                <DropdownStyled
                  options={jobItems}
                  labelField="label"
                  valueField="Job"
                  disabled={!!row.overHead}
                  isLoading={jobs.isLoading}
                  error={jobs.isError}
                  className={`h-full min-h-40px  printHide ${
                    showErrors && !(row.job || row.overHead) && 'p-invalid'
                  }`}
                  filter={true}
                  value={row.job}
                  onChange={(e) => {
                    const rowCopy = { ...row };
                    delete rowCopy.phaseCode;

                    const value = e.target.value;
                    if (value) {
                      rowCopy.job = e.target.value;
                    } else {
                      delete rowCopy.job;
                    }

                    updateDetails(empID, options.rowIndex, rowCopy);
                  }}
                />
                <span className="printMedia printShow">
                  {selectedInfo?.label}
                </span>
              </span>
              {showErrors && !(row.job || row.overHead) && (
                <FormErrorMessage errorMessage="Job or OverHead is required" />
              )}
            </div>
          );
        }}
        style={{ minWidth: '200px', maxWidth: '200px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        field="phaseCode"
        header="Phase Code"
        body={(row, options) => {
          const job = row.job;
          const phases = useQuery({
            queryKey: ['getJCJobPhases', selectedCompany, job],
            queryFn: ({ signal }) => {
              return fetchJCJobPhases(
                {
                  companyID: selectedCompany!.id,
                  job,
                },
                signal
              );
            },
            refetchOnWindowFocus: false,
            enabled: !!(selectedCompany && job),
          });

          if (!job) {
            return;
          }

          return (
            <div className="w-full">
              <span className="h-full w-full">
                <DropdownStyled
                  options={phases.data}
                  labelField="Label"
                  valueField="Phase"
                  isLoading={phases.isLoading}
                  error={phases.isError}
                  className={`h-full min-h-40px  printHide ${
                    showErrors && row.job && !row.phaseCode && 'p-invalid'
                  }`}
                  filter={true}
                  value={row.phaseCode}
                  onChange={(e) => {
                    const rowCopy = { ...row };
                    rowCopy.phaseCode = e.target.value;
                    updateDetails(empID, options.rowIndex, rowCopy);
                  }}
                />
                <span className="printMedia printShow">{row.phaseCode}</span>
              </span>
              {showErrors && row.job && !row.phaseCode && (
                <FormErrorMessage errorMessage="PhaseCode is required" />
              )}
            </div>
          );
        }}
        style={{ minWidth: '200px', maxWidth: '200px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        field="overHead"
        header="Overhead"
        body={(row, options) => {
          return (
            <div className="w-full">
              <span className="h-full">
                <DropdownStyled
                  options={glAccountsItems}
                  labelField="label"
                  valueField="GLAcct"
                  disabled={!!row.job}
                  isLoading={glAccounts.isLoading}
                  error={glAccounts.isError}
                  className={`h-full min-h-40px  printHide ${
                    showErrors && !(row.job || row.overHead) && 'p-invalid'
                  }`}
                  filter={true}
                  value={row.overHead}
                  onChange={(e) => {
                    const rowCopy = { ...row };
                    const value = e.target.value;
                    if (value) {
                      rowCopy.overHead = value;
                    } else {
                      delete rowCopy.overHead;
                    }

                    updateDetails(empID, options.rowIndex, rowCopy);
                  }}
                />
                <span className="printMedia printShow">{row.overHead}</span>
              </span>
              {showErrors && !(row.job || row.overHead) && (
                <FormErrorMessage errorMessage="Job or OverHead is required" />
              )}
            </div>
          );
        }}
        style={{ minWidth: '200px', maxWidth: '200px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        style={{ maxWidth: '30px' }}
        headerClassName={`hidden`}
        className={`text-standard blackText printHide tableCell justify-content-center expandedTableCell`}
        footerClassName="tableFooter"
      />
      <Column
        body={(record, options) => {
          if (options.rowIndex + 1 === details.length) {
            return (
              <i
                className="pi pi-plus text-17px cursor-pointer"
                onClick={() => {
                  addNewDetail(empID);
                }}
              />
            );
          }

          return;
        }}
        style={{ maxWidth: '30px' }}
        headerClassName={`hidden`}
        className={`text-standard blackText printHide tableCell justify-content-center expandedTableCell`}
        footerClassName="tableFooter"
      />
      <Column
        body={(record, options) => {
          if (details.length !== 1 && options.rowIndex + 1 !== details.length) {
            return (
              <i
                className="pi pi-trash text-17px cursor-pointer"
                onClick={() => {
                  removeDetail(empID, options.rowIndex);
                }}
              />
            );
          }

          return;
        }}
        style={{ maxWidth: '30px' }}
        headerClassName={`hidden`}
        className={`text-standard blackText printHide tableCell justify-content-center expandedTableCell`}
        footerClassName="tableFooter"
      />
    </Table>
  );
};

export default PCReimbursementTableDetails;
