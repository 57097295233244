import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { Toast } from 'primereact/toast';
import RegisterForm, {
  RegisterFormFields,
} from '../../../features/user/RegisterForm';
import { auth } from '../../../../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import firebaseErrorMessages from '../../../components/errorMessages/firebase';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import SuccessMessage from '../../../features/user/SuccessMessage';
import { postUsers, postUsersArgs } from '../../../services/UsersService';
import LoadingButton from '../../../components/inputs/LoadingButton';
import {
  processAccessForm,
  removeDuplicates,
} from 'apps/tmr-frontend/src/utils/roleUtils';
import { getRolesInfo, postRoleArgs } from '../../../services/RolesService';
import { Access } from '../../../Interfaces/Role.interfaces';

const UserForm = () => {
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const [defaultAccess, setDefaultAccess] = useState<Access[]>([]);
  const formMethods = useForm<RegisterFormFields>();
  const { handleSubmit, watch } = formMethods;
  const roles = watch('roles');

  const getAccess = (rolesData?: postRoleArgs[]) => {
    if (!rolesData) {
      return [];
    }

    return removeDuplicates(
      rolesData.reduce((acc, role) => {
        acc = [...acc, ...role.access];

        return acc;
      }, [] as Access[])
    );
  };

  useQuery({
    queryKey: ['getRolesInfo', roles],
    queryFn: ({ signal }) => getRolesInfo(roles, signal),
    refetchOnWindowFocus: false,
    enabled: !!(roles && roles?.length),
    onSuccess: (data) => {
      setDefaultAccess(getAccess(data));
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as { code: string };
      showToast(
        'error',
        toast,
        'Role Access Error',
        firebaseErrorMessages[errorData?.code] ||
          `Couldn't find role access! please try again later`,
        3000
      );
    },
  });

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: (data: postUsersArgs) => {
      return postUsers(data);
    },
    onSuccess: async (data) => {
      await sendPasswordResetEmail(auth, data.email);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as { code: string };
      showToast(
        'error',
        toast,
        'Register User Error',
        firebaseErrorMessages[errorData?.code] ||
          'An error ocurred! please try again later',
        3000
      );
    },
  });

  const onSubmit = (data: RegisterFormFields) => {
    const { company, access, secGroups, ...otherData } = data;

    const sequenceReports = access.reduce((acc, item) => {
      if (Array.isArray(item.approvalOption)) {
        acc[item.report] = true;
      }

      return acc;
    }, {} as Record<string, boolean>);

    const list = processAccessForm(access);
    const removedRepeated = list.filter((acc) => {
      const def = defaultAccess.find((defAcc) => defAcc.report === acc.report);
      const isSequence = sequenceReports[acc.report];

      if (
        def &&
        acc.shouldNotify == def.shouldNotify &&
        acc.editable == def.editable &&
        (isSequence
          ? acc.approvalOrder == def.approvalOrder
          : acc.shouldApprove == def.shouldApprove)
      ) {
        return false;
      }

      return true;
    });

    const blocked = defaultAccess
      .filter(
        (defAccess) =>
          !list.find((element) => defAccess.report === element.report)
      )
      .map((acc) => {
        return { report: acc.report, blocked: true };
      });

    const payload = {
      ...otherData,
      isAdmin: otherData.isAdmin === 'yes',
      roles: otherData.roles || [],
      company: company?.length ? company : undefined,
      access: [...removedRepeated, ...blocked],
      securityGroups: secGroups?.length ? secGroups : undefined,
    };

    mutate(payload);
  };

  useEffect(() => {
    if (!roles || !roles?.length) {
      setDefaultAccess([]);
    }
  }, [roles]);

  return (
    <div className="flex flex-column justify-content-center gap-5 pt-8">
      <ErrorToast toastRef={toast} />
      <div className="text-35px font-bold text-center mb-5">Register User</div>
      {!isSuccess && (
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="max-w-600px mx-auto flex flex-column gap-5"
          >
            <RegisterForm defaultRolesAccess={defaultAccess} />
            <div className="flex flex-wrap gap-2 mb-3">
              <Button
                onClick={() => {
                  navigate('/admin/users');
                }}
                className="w-15rem bluwaiRed border-0 flex m-auto py-3"
              >
                <span className="mx-auto text-22px text-white">Cancel</span>
              </Button>
              <LoadingButton
                isLoading={isLoading}
                label="Register"
                className="w-15rem bluwaiBlue border-0 flex m-auto py-3"
                fontSize="text-22px"
              />
            </div>
          </form>
        </FormProvider>
      )}
      {isSuccess && (
        <SuccessMessage message="The user has been created and an email was sent to set the password!" />
      )}
    </div>
  );
};

export default UserForm;
