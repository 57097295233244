import React, { useCallback } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatCurrency } from '../../../../utils/formatUtils';
import { nextSortState, sortByField } from '../../../../utils/sortUtils';
import {
  DataTablePFSEvent,
  DataTableRowMouseEventParams,
  DataTableSortOrderType,
} from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import { ResidentStatus } from '../../../Interfaces/Accounting/ResidentStatus.interface';

type ARAgedTableProps = {
  residentsStatus: ResidentStatus[];
};

const ARAgedTableYardi = React.forwardRef<HTMLDivElement, ARAgedTableProps>(
  ({ residentsStatus }, ref) => {
    const [sortState, setSortState] = React.useState<{
      field: string;
      order: DataTableSortOrderType;
    }>({ field: '', order: null });

    const [sortedTransactions, setSortedTransactions] = React.useState([
      ...residentsStatus,
    ]);

    const calcHeight = (rows: number) => {
      const headerHeight = 32;
      const footerHeight = 54;
      const rowHeight = 31;
      return headerHeight + footerHeight + rows * rowHeight + 3;
    };

    const onRowHover = (e: DataTableRowMouseEventParams) => {
      const cell = e.originalEvent.target as HTMLElement;
      const row = cell.closest('tr') as HTMLElement;
      animateCellText(row);
    };

    let owed0to30 = 0;
    let owed31to60 = 0;
    let owed61to90 = 0;
    let over90 = 0;
    let balance = 0;
    const retainage = 0;
    residentsStatus.forEach((item: ResidentStatus) => {
      owed0to30 = owed0to30 + Number(item.owed0to30);
      owed31to60 = owed31to60 + Number(item.owed31to60);
      owed61to90 = owed61to90 + Number(item.owed61to90);
      over90 = over90 + Number(item.over90);
      balance = balance + Number(item.balance);
    });

    const getTotalAR = () => {
      let total = 0;
      residentsStatus.forEach((item: ResidentStatus) => {
        // if (item.owed0to30) {
        //   total += Number(item.owed0to30);
        //   return;
        // }

        // if (item.owed31to60) {
        //   total += Number(item.owed31to60);
        //   return;
        // }

        // if (item.owed61to90) {
        //   total += Number(item.owed61to90);
        //   return;
        // }

        // if (item.over90) {
        //   total += Number(item.over90);
        //   return;
        // }
        if (item.balance) {
          total += Number(item.balance);
          return;
        }
      });

      return total;
    };

    const handleSort = (event: DataTablePFSEvent) => {
      const { reset, field, order } = nextSortState(
        event.sortField,
        sortState.field
      );
      setSortState({ field, order });

      if (reset) {
        setSortedTransactions([...residentsStatus]);
        return;
      }

      const sortedData = [...residentsStatus].sort((a: any, b: any) => {
        const key = event.sortField as keyof ResidentStatus;
        const aValue = a[key];
        const bValue = b[key];
        return sortByField(aValue, bValue, order ?? 0);
      });

      setSortedTransactions(sortedData);
    };

    return (
      <div className="pdfDivFitContent">
        <Table
          id="ar-aged-table"
          ref={ref}
          data={sortedTransactions}
          className={`mx-3 dashboardOptionShadow tableFirstLineStyled`}
          calcHeight={useCallback(calcHeight, [])}
          onRowMouseEnter={useCallback(onRowHover, [])}
          sortField={sortState.field}
          sortOrder={sortState.order}
          onSort={useCallback(handleSort, [sortState.field, residentsStatus])}
          hideColumns={true}
        >
          <Column
            field="Customer"
            header="Customer"
            body={(resident) => {
              return (
                <div className="scroll-text">{resident.customer.name}</div>
              );
            }}
            headerClassName={`tableHeader font-normal justify-content-center`}
            style={{ minWidth: '250px', maxWidth: '250px' }}
            className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="contractID"
            header="Contract #"
            style={{ minWidth: '110px' }}
            headerClassName="tableHeader font-normal"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />

          <Column
            header="Current"
            style={{ minWidth: '130px' }}
            field="owed0to30"
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(owed0to30)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            header="31 - 60"
            style={{ minWidth: '130px' }}
            field="owed31to60"
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(owed31to60)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            header="61 - 90"
            style={{ minWidth: '130px' }}
            field="owed61to90"
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(owed61to90)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            header="Over 90"
            style={{ minWidth: '130px' }}
            field="over90"
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(over90)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            header="Balance"
            style={{ minWidth: '130px' }}
            field="balance"
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(balance)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
        </Table>
        <div className="ml-auto w-full px-3 sm:px-0 sm:w-24rem mr-5 my-5 text-standard">
          <div className="flex justify-content-between">
            <span>Receivables</span>
            <span id="totalReceivables">{formatCurrency(getTotalAR())}</span>
          </div>
          <div className="flex justify-content-between mt-2">
            <span>A/R - Retainage</span>
            <span id="totalRetainage">{formatCurrency(retainage)}</span>
          </div>
          <div className="flex justify-content-between mt-1 border-top-1 border-bottom-2">
            <span className="font-bold">Grand Total</span>
            <span id="grandTotal">{formatCurrency(getTotalAR())}</span>
          </div>
        </div>
      </div>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<ARAgedTableProps>,
  nextTransactions: Readonly<ARAgedTableProps>
) => {
  return prevTransactions.residentsStatus === nextTransactions.residentsStatus;
};

export default React.memo(ARAgedTableYardi, transactionsAreEqual);
