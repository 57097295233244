import axios from 'axios';
import { auth } from '../../firebase';
import { cleanJsonEmptyValues } from '../../utils/formatUtils';
import { TimeSheetArgs } from '../Interfaces/Accounting/ACForms.interfaces';
import { PCReimbursementArgs } from '../Interfaces/Accounting/Payroll.interface';

const apiUrl = process.env['NX_API_URL'];

type PREmployeeFilters = {
  companyID: string;
  status?: boolean;
};

export const fetchPREmployeeList = async (
  params: PREmployeeFilters,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/payroll/employeeList`, {
    signal,
    params,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fetchPREmployeeEarnings = async (
  companyID: string,
  employeeStatus: string,
  period: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/payroll/earnings`, {
    signal,
    params: { companyID, employeeStatus, period },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fetchPRPeriodDates = async (
  companyID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/payroll/period`, {
    signal,
    params: { companyID },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fetchPREmployeesTimesheet = async (
  companyID: string,
  submittedID?: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/payroll/employeesTimesheet`, {
    signal,
    params: { companyID, draftID: submittedID },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

type fetchPRTimesheetsArgs = {
  companyID: string;
  userID?: string;
  isSubmitted?: boolean;
  isApproved?: boolean;
  periodStart?: Date;
  periodEnd?: Date;
};

export const fetchPRTimesheets = async (
  payload: fetchPRTimesheetsArgs,
  signal?: AbortSignal
) => {
  const cleanPayload = cleanJsonEmptyValues(payload);

  const response = await axios.get(`${apiUrl}/api/payroll/timesheet`, {
    signal,
    params: cleanPayload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fetchPRTimesheetTemplate = async (
  companyID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/payroll/timesheetTemplate`, {
    signal,
    params: { companyID },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  return response.data;
};

export const postTimesheetDraft = async (
  companyID: string,
  draft: TimeSheetArgs,
  periodStart: Date,
  periodEnd: Date
) => {
  const response = await axios.post(
    `${apiUrl}/api/payroll/timesheet`,
    { companyID, employees: draft, periodStart, periodEnd },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const putTimesheetDraft = async (
  dratID: string,
  draft: TimeSheetArgs,
  periodStart: Date,
  periodEnd: Date,
  fromApproval?: boolean
) => {
  const response = await axios.put(
    `${apiUrl}/api/payroll/timesheet/${dratID}`,
    { employees: draft, periodStart, periodEnd, fromApproval: !!fromApproval },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const putTimesheetSubmitDraft = async (dratID: string) => {
  const response = await axios.put(
    `${apiUrl}/api/payroll/timesheet/submit/${dratID}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const putTimesheetApproveDraft = async (dratID: string) => {
  const response = await axios.put(
    `${apiUrl}/api/payroll/timesheet/approve/${dratID}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};

export const putTimesheetRejectDraft = async (
  dratID: string,
  reason: string
) => {
  const response = await axios.put(
    `${apiUrl}/api/payroll/timesheet/reject/${dratID}`,
    { reason },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};

export const fetchPCReimbursement = async (
  companyID: string,
  submittedID?: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/payroll/employeesPCReimbursement`,
    {
      signal,
      params: { companyID, draftID: submittedID },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

type fetchPCReimburusementsFilters = {
  companyID: string;
  userID?: string;
  isSubmitted?: boolean;
  isApproved?: boolean;
};

export const fetchPCReimburusements = async (
  payload: fetchPCReimburusementsFilters,
  signal?: AbortSignal
) => {
  const cleanPayload = cleanJsonEmptyValues(payload);

  const response = await axios.get(
    `${apiUrl}/api/payroll/pettyCashReimbursement`,
    {
      signal,
      params: cleanPayload,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const postPCReimbursementDraft = async (
  companyID: string,
  draft: PCReimbursementArgs
) => {
  console.log(draft);
  const formData = new FormData();
  formData.append('companyID', companyID);

  draft.forEach((employee, index) => {
    formData.append(
      `employees[${index}]`,
      JSON.stringify({
        empID: employee.empID,
        total: employee.total,
        details: employee.details,
        amount: employee.amount,
        job: employee.job,
        phaseCode: employee.phaseCode,
        overHead: employee.overHead,
      })
    );

    if (employee.attachment) {
      formData.append(`employees[${index}][attachment]`, employee.attachment);
    }
  });

  const response = await axios.post(
    `${apiUrl}/api/payroll/pettyCashReimbursement`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const putPCReimbursementDraft = async (
  dratID: string,
  draft: PCReimbursementArgs,
  fromApproval?: boolean
) => {
  const formData = new FormData();
  formData.append('fromApproval', `${fromApproval ?? false}`);

  draft.forEach((employee, index) => {
    formData.append(
      `employees[${index}]`,
      JSON.stringify({
        empID: employee.empID,
        total: employee.total,
        details: employee.details,
        amount: employee.amount,
        job: employee.job,
        phaseCode: employee.phaseCode,
        overHead: employee.overHead,
      })
    );

    if (employee.attachment) {
      formData.append(`employees[${index}][attachment]`, employee.attachment);
    }
  });

  const response = await axios.put(
    `${apiUrl}/api/payroll/pettyCashReimbursement/${dratID}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const putPCReimbursementSubmitDraft = async (dratID: string) => {
  const response = await axios.put(
    `${apiUrl}/api/payroll/pettyCashReimbursement/submit/${dratID}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const putReimbursementApproveDraft = async (dratID: string) => {
  const response = await axios.put(
    `${apiUrl}/api/payroll/pettyCashReimbursement/approve/${dratID}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};

export const putReimbursementRejectDraft = async (
  dratID: string,
  reason: string
) => {
  const response = await axios.put(
    `${apiUrl}/api/payroll/pettyCashReimbursement/reject/${dratID}`,
    { reason },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};

export const fetchPCReimbursementEmpAttachment = async (
  draftID: string,
  empID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/payroll/pettyCashReimbursement/${draftID}/${empID}/file`,
    {
      signal,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response;
};
