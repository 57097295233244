import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { fecthCBProgressWorksheet } from '../../../services/ContractBillingsService';
import ContractProgressTable from './ContractProgressTable';
import { useFormContext } from 'react-hook-form';
import { fetchContractBillingFilters } from '../CBFilters';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';
import ProgressBillingProcess from './ProgressBillingProcess';
import {
  BillingDraft,
  ContractProgressItem,
} from '../../../Interfaces/Accounting/ContractBillings.interface';
import ProgressBillingLastApproved from './ProgressBillingLastApproved';

const ContractProgressTableContainer = () => {
  const { selectedCompany } = useCompanyContext();
  const { rolesAcess } = useRolesAccessContext();
  const { watch } = useFormContext<fetchContractBillingFilters>();
  const filters = watch();
  const [items, setItems] = useState<ContractProgressItem[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [drafts, setDrafts] = useState<Record<string, BillingDraft>>({});
  const [isEditing, setIsEditing] = useState(false);
  const isEnabled = selectedCompany && filters.contract;
  const access = rolesAcess?.find(
    (access) => access.report === 'contract_progress_worksheet'
  );

  const completedItems = (
    items: ContractProgressItem[],
    amounts: Record<number, number>
  ) => {
    return items.map((item) => {
      const index = parseInt(item.Item);
      return { ...item, NewBilling: amounts[index] ?? null };
    });
  };

  const { data, isFetching, isError, refetch } = useQuery({
    queryKey: [
      'getCBProgress',
      selectedCompany,
      filters.contract,
      filters.itemsState,
      filters.submittedID,
      filters.info,
    ],
    queryFn: ({ signal }) => {
      setIsProcessing(true);

      return fecthCBProgressWorksheet(
        {
          companyID: selectedCompany!.id,
          contract: filters.contract ?? '',
          edit: filters.submittedID ? undefined : !!access?.editable,
          draftID: filters.submittedID,
          hideBilled: filters.itemsState === 'hide' ? true : false,
          info: filters.info,
        },
        signal
      );
    },
    onSuccess: (data) => {
      const draft = drafts[filters.contract!] ?? {};
      const contractLastUpdates = draft.updatedAt;
      const draftUpdate = new Date(data.draft?.updatedAt);

      if (
        !contractLastUpdates ||
        contractLastUpdates?.getTime() < draftUpdate?.getTime()
      ) {
        setDrafts((drafts) => {
          const draft = drafts[filters.contract!] ?? {};
          draft.billing = data.draft?.amounts;
          draft.retainage = data.draft?.releasedRetainage;
          draft.updatedAt = null;
          drafts[filters.contract!] = draft;
          return drafts;
        });

        setItems(data.items);
      } else {
        setItems(completedItems(data.items, draft.billing ?? {}));
      }
      setIsProcessing(false);
    },
    onError: () => {
      setIsProcessing(false);
    },
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });

  const UpdateTime = () => {
    setDrafts((drafts) => {
      drafts[filters.contract!].updatedAt = new Date();
      return { ...drafts };
    });
  };

  const changeBillingDraft = (item: number, value?: number) => {
    setDrafts((drafts) => {
      const newBillings: Record<number, number> =
        drafts[filters.contract!].billing ?? {};

      if (value) {
        newBillings[item] = value;
        drafts[filters.contract!].billing = newBillings;
      } else if (newBillings[item]) {
        delete newBillings[item];
      } else {
        return drafts;
      }

      drafts[filters.contract!].billing = { ...newBillings };
      return { ...drafts };
    });

    UpdateTime();
  };

  const changeRetainage = (number?: number) => {
    setDrafts((drafts) => {
      drafts[filters.contract!].retainage = number;
      return { ...drafts };
    });

    UpdateTime();
  };

  if (!isEnabled) {
    return (
      <div className="mt-4">
        <ErrorMessage
          severity="info"
          content={'To start the search please select a Job.'}
        />
      </div>
    );
  }

  if (isFetching || isProcessing)
    return (
      <div className="text-center mx-auto mt-3">
        <ProgressSpinner />
      </div>
    );

  if (isError)
    return (
      <ErrorMessage
        content={'Failed to obtain data! Please try again later.'}
      />
    );

  return (
    <div className="pdfDivFitContent">
      {!!access?.editable && !filters.submittedID && (
        <div className="ml-3 mt-4">
          <ProgressBillingLastApproved onSuccess={refetch} />
        </div>
      )}
      <ContractProgressTable
        items={items || []}
        releasedRetainage={drafts[filters.contract!]?.retainage ?? undefined}
        isApproval={!!filters.submittedID}
        access={access ?? { report: 'contract_progress_worksheet' }}
        updateIsEditing={setIsEditing}
        updateBillingDraft={changeBillingDraft}
        updateReleasedRetainage={changeRetainage}
      />
      <div className="mt-3 flex gap-4 w-fit ml-auto mr-3 printHide">
        <ProgressBillingProcess
          draftAmounts={drafts[filters.contract!]?.billing ?? {}}
          retainage={drafts[filters.contract!]?.retainage}
          defaultDraftID={data.draft?.id}
          isSubmitted={data.draft?.submitted}
          approvalMode={!!filters.submittedID}
          access={access ?? { report: 'contract_progress_worksheet' }}
          blockAll={isEditing}
          defaultBlockSave={!drafts[filters.contract!]?.updatedAt}
        />
      </div>
    </div>
  );
};
export default ContractProgressTableContainer;
